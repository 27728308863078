import React from "react";
import { Card } from "react-bootstrap";

function MyCard( props ){

    return (
        <Card style={{ width: '18rem' }} className={ props.className }>
            <Card.Body>
                <Card.Title className={'text-center'}>{ props.title }</Card.Title>
                <Card.Text className={'text-center'}>{ props.disabled ? '(prochainement)' : <>&nbsp;</> }</Card.Text>
            </Card.Body>
            <Card.Img variant="bottom" src={ props.img } />
        </Card>
    );

}

MyCard.defaultProps = {
    disabled: false,
}

export default MyCard;