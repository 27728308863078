import React, {useRef, useState} from "react";
import BottomNav from "./BottomNav";
import {useDispatch, useSelector} from "react-redux";
import Keyboard from "react-simple-keyboard";
import {list_deces_lieu_type, list_obseque_type} from "../misc/listing";
import POI from "./sub/POI";

function Page6() {

    const deces_lieu_type = useSelector( ( state ) => state.deces_lieu_type );
    const deces_lieu_ville = useSelector( ( state ) => state.deces_lieu_ville );
    const obseque_type = useSelector( ( state ) => state.obseque_type );
    const deces_lieu_hopital_poi = useSelector( ( state ) => state.deces_lieu_hopital_poi );
    const deces_lieu_maison_de_repos_poi = useSelector( ( state ) => state.deces_lieu_maison_de_repos_poi );
    const deces_lieu_ville_poi = useSelector( ( state ) => state.deces_lieu_ville_poi );
    const logged_in = useSelector( ( state ) => state.logged_in );
    const dispatch = useDispatch();

    const keyboard = useRef()

    const [ inputFocused, setInputFocused ] = useState( null )
    const [ layout, setLayout ] = useState("default")

    const onChange = input => {
        dispatch( { type: 'set_deces_lieu_ville', payload: input } )
    };

    const handleShift = () => {
        const newLayoutName = layout === "default" ? "shift" : "default";
        setLayout(newLayoutName);
    };

    const onKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") handleShift();
        if (button === "{enter}") {
            setInputFocused(null);
            inputFocused.blur();
        }
    };

    return (
        <>
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-2">
                <h1 className="text-center font-gold mt-5">Où le décès a-t-il eu lieu?</h1>
                <div className="row justify-content-around mb-5">
                    {
                        list_deces_lieu_type.map((value, index) => {
                            return (
                                <div className="col-6 mb-3" key={ index }>
                                    <button type="button" className={ "btn btn-lg btn-block" + ( index === deces_lieu_type ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_deces_lieu_type', payload: index } ) }>{ value }</button>
                                </div>
                            );
                        })
                    }
                </div>
                {
                    // Si le choix est 'hopital', on choisi l'hopital
                    deces_lieu_type === 0 && <POI poi={ deces_lieu_hopital_poi } route={ '/ChoixHopital' } action_type={ 'set_deces_lieu_hopital_poi' } title={'Hôpital non-sélectionné'} button_select={'Choisissez un Hôpital'} button_update={'Modifier cet Hôpital'} />
                }
                {
                    // Si le choix est 'maison de repos', on choisi la maison de repos
                    deces_lieu_type === 1 && <POI poi={ deces_lieu_maison_de_repos_poi } route={ '/ChoixMaisonDeRepos' } action_type={ 'set_deces_lieu_maison_de_repos_poi' } title={'Maison de repos non-sélectionnée'} button_select={'Choisissez une Maison de repos'} button_update={'Modifier cette maison de repos'} />
                }
                {
                    // Si le choix est 'domicile', on choisi la ville
                    deces_lieu_type === 2 && <POI poi={ deces_lieu_ville_poi } route={ '/ChoixVille' } action_type={ 'set_deces_lieu_ville_poi' } title={'Ville non-sélectionnée'} button_select={'Choisissez une ville'} button_update={'Modifier cette ville'} />
                }
                {
                    // Si le choix est 'autre', on entre la ville manuellement
                    deces_lieu_type === 3 && <>
                        <h1 className="text-center font-gold">Dans quelle ville ?</h1>
                        <div className="row">
                            <div className="col-10 m-auto">
                                <form autoComplete="off">
                                    <div className="form-group">
                                        <label htmlFor="nom">Saisissez le nom de la ville</label>
                                        <input name="nom" className="form-control" value={ deces_lieu_ville }  onFocus={ (event) => setInputFocused( event.target ) }  onChange={ (event) => dispatch( { type: 'set_deces_lieu_ville', payload: event.target.value } ) } />
                                    </div>
                                </form>
                            </div>
                        </div>
                        {
                            inputFocused !== null && <div className="row">
                                <div className="col-10 m-auto">
                                    <Keyboard
                                        keyboardRef={r => (keyboard.current = r)}
                                        layoutName="default"
                                        onChange={onChange}
                                        inputName="code"
                                        onKeyPress={onKeyPress}
                                        layout={
                                            {
                                                default: [
                                                    '@ a z e r t y u i o p {bksp}',
                                                    '{lock} q s d f g h j k l m {enter}',
                                                    '{shift} w x c v b n . - _ {shift}',
                                                    '{space}'
                                                ],
                                                shift: [
                                                    '@ A Z E R T Y U I O P {bksp}',
                                                    '{lock} Q S D F G H J K L M {enter}',
                                                    '{shift} W X C V B N . - _ {shift}',
                                                    '{space}'
                                                ]
                                            }
                                        }
                                        display={
                                            {
                                                '{bksp}': 'supp.',
                                                '{enter}': 'entrer',
                                                '{shift}': 'maj.',
                                                '{lock}': 'ver. maj.',
                                                '{space}' : ' '
                                            }
                                        }
                                        buttonTheme={
                                            [
                                                {
                                                    class: "bg-info btn btn-info",
                                                    buttons: "{enter}"
                                                }
                                            ]
                                        }
                                    />
                                </div>
                            </div>
                        }
                    </>
                }
                {
                    inputFocused === null &&
                    deces_lieu_type !== null &&
                    <>
                        <h1 className="text-center font-gold mt-5">Quel type d’obsèques souhaitez-vous?</h1>
                        <div className="row justify-content-around">
                        {
                            list_obseque_type.map((value, index) => {
                                return (
                                    <div className="col-6 mb-3" key={ index }>
                                        <button type="button" className={ "btn btn-lg btn-block text-capitalize" + ( index === obseque_type ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_obseque_type', payload: index } ) }>{ value.label }</button>
                                    </div>
                                );
                            })
                        }
                        </div>
                    </>
                }
            </div>
        </div>
        <BottomNav precedent_page={ logged_in ? '/Page4' : '/Page5'} suivant_page={ obseque_type === 1 ? '/Page6b' : '/Page7'} suivant_enable={ obseque_type !== null } />
        </>
    );

}

export default Page6;