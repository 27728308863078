import React from "react";
import BottomNav from "./BottomNav";
import {
    list_concession_existante,
    list_concession_existante_type,
    list_concession_nouvelle_type,
    list_concession_existante_caveau_monument,
    list_concession_existante_pleine_terre_monument,
    list_concession_nouvelle_nombre_de_place_caveau,
    list_concession_nouvelle_nombre_de_place_pleine_terre,
    list_concession_existante_caveau_monument_ouverture,
    list_concession_existante_nombre_de_place_pleine_terre_avec_monument,
    list_concession_existante_nombre_de_place_pleine_terre_sans_monument
} from '../misc/listing'
import {useDispatch, useSelector} from "react-redux";
import VignettePhoto from "./sub/VignettePhoto";

function Page8() {

    const concession_existante = useSelector( ( state ) => state.concession_existante );
    const concession_existante_type = useSelector( ( state ) => state.concession_existante_type );

    const concession_existante_caveau_monument = useSelector( ( state ) => state.concession_existante_caveau_monument );
    const concession_existante_caveau_monument_ouverture = useSelector( ( state ) => state.concession_existante_caveau_monument_ouverture );
    const concession_existante_pleine_terre_monument = useSelector( ( state ) => state.concession_existante_pleine_terre_monument );
    const concession_existante_nombre_de_place_pleine_terre_avec_monument = useSelector( ( state ) => state.concession_existante_nombre_de_place_pleine_terre_avec_monument );
    const concession_existante_nombre_de_place_pleine_terre_sans_monument = useSelector( ( state ) => state.concession_existante_nombre_de_place_pleine_terre_sans_monument );

    const concession_nouvelle_type = useSelector( ( state ) => state.concession_nouvelle_type );
    const concession_nouvelle_nombre_de_place_caveau = useSelector( ( state ) => state.concession_nouvelle_nombre_de_place_caveau );
    const concession_nouvelle_nombre_de_place_pleine_terre = useSelector( ( state ) => state.concession_nouvelle_nombre_de_place_pleine_terre );

    const dispatch = useDispatch();

    return (
        <>
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-2">
                <h1 className="text-center font-gold mt-5 mb-3">Avez-vous une concession existante?</h1>
                <div className="row justify-content-around mb-5">
                    {
                        list_concession_existante.map((value, index) => {
                            return (
                                <div className="col-4 mb-3" key={ index }>
                                    <button type="button" className={ "btn btn-lg btn-block" + ( index === concession_existante ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_concession_existante', payload: index } ) }>{ value }</button>
                                </div>
                            );
                        })
                    }
                </div>
                {
                    concession_existante === 0 && <>
                        <div className="row justify-content-around mb-5">
                            {
                                list_concession_existante_type.map((value, index) => {
                                    return (
                                        !( ( concession_existante_type === 0 && concession_existante_caveau_monument !== null ) || ( concession_existante_type === 1 && concession_existante_pleine_terre_monument !== null ) ) ?
                                            <VignettePhoto
                                                img={ value.img }
                                                key={ index }
                                                selected={ index === concession_existante_type }
                                                onClick={ () => dispatch( { type: 'set_concession_existante_type', payload: index } ) }
                                                nom={ value.nom }
                                            />
                                        :
                                            <div className="col-4 mb-3" key={ index }>
                                                <button type="button" className={ "btn btn-lg btn-block" + ( index === concession_existante_type ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_concession_existante_type', payload: index } ) }>{ value.nom }</button>
                                            </div>
                                    );
                                })
                            }
                        </div>
                        {
                            concession_existante_type === 0 && <>
                                <div className="row justify-content-around mb-5">
                                    {
                                        list_concession_existante_caveau_monument.map( (item, index) => {
                                            return (
                                                <div className="col-4 mb-3" key={ index }>
                                                    <button type="button" className={ "btn btn-lg btn-block" + ( index === concession_existante_caveau_monument ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_concession_existante_caveau_monument', payload: index } ) }>{ item.nom }</button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        }
                        {
                            concession_existante_type === 1 && <>
                                <div className="row justify-content-around mb-5">
                                    {
                                        list_concession_existante_pleine_terre_monument.map( (item, index) => {
                                            return (
                                                <div className="col-4 mb-3" key={ index }>
                                                    <button type="button" className={ "btn btn-lg btn-block" + ( index === concession_existante_pleine_terre_monument ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_concession_existante_pleine_terre_monument', payload: index } ) }>{ item.nom }</button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        }
                        {
                            concession_existante_type === 1 && concession_existante_pleine_terre_monument !== null && <>
                                <h1 className="text-center font-gold mt-5 mb-3">Nombre de places à créer ?</h1>
                                <div className="row justify-content-around mb-5">
                                    {
                                        concession_existante_pleine_terre_monument === 0 && list_concession_existante_nombre_de_place_pleine_terre_avec_monument.map( (item, index) => {
                                            return (
                                                <div className="col-3 mb-3" key={ index }>
                                                    <button type="button" className={ "btn btn-lg btn-block" + ( index === concession_existante_nombre_de_place_pleine_terre_avec_monument ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_concession_existante_nombre_de_place_pleine_terre_avec_monument', payload: index } ) }>{ item.nom }</button>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        concession_existante_pleine_terre_monument === 1 && list_concession_existante_nombre_de_place_pleine_terre_sans_monument.map( (item, index) => {
                                            return (
                                                <div className="col-3 mb-3" key={ index }>
                                                    <button type="button" className={ "btn btn-lg btn-block" + ( index === concession_existante_nombre_de_place_pleine_terre_sans_monument ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_concession_existante_nombre_de_place_pleine_terre_sans_monument', payload: index } ) }>{ item.nom }</button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        }
                        {
                            concession_existante_type === 0 && concession_existante_caveau_monument === 0 && <>
                                <div className="row justify-content-around">
                                    {
                                        list_concession_existante_caveau_monument_ouverture.map( (item, index) => {
                                            return (
                                                <VignettePhoto
                                                    img={ item.img }
                                                    key={ index }
                                                    selected={ index === concession_existante_caveau_monument_ouverture }
                                                    onClick={ () => dispatch( { type: 'set_concession_existante_caveau_monument_ouverture', payload: index } ) }
                                                    nom={ item.nom }
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </>
                        }
                    </>
                }
                {
                    concession_existante === 1 && <>
                        <h2 className="text-center font-gold mt-5 mb-3">Quel type de concession souhaitez-vous créer?</h2>
                        <div className="row justify-content-around mb-5">
                            {
                                list_concession_nouvelle_type.map((value, index) => {
                                    return (
                                        <VignettePhoto
                                            img={ value.img }
                                            key={ index }
                                            selected={ index === concession_nouvelle_type }
                                            onClick={ () => dispatch( { type: 'set_concession_nouvelle_type', payload: index } ) }
                                            nom={ value.nom }
                                        />
                                    );
                                })
                            }
                        </div>
                        <h2 className="text-center font-gold mt-5 mb-3">Sélectionnez le nombre de places</h2>
                        <div className="row justify-content-around mb-5">
                            {
                                concession_nouvelle_type === 0 && list_concession_nouvelle_nombre_de_place_caveau.map((value, index) => {
                                    return (
                                        <div className="col-3 mb-3" key={ index }>
                                            <button type="button" className={ "btn btn-lg btn-block" + ( index === concession_nouvelle_nombre_de_place_caveau ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_concession_nouvelle_nombre_de_place_caveau', payload: index } ) }>{ value.nom }</button>
                                        </div>
                                    );
                                })
                            }
                            {
                                concession_nouvelle_type === 1 && list_concession_nouvelle_nombre_de_place_pleine_terre.map((value, index) => {
                                    return (
                                        <div className="col-3 mb-3" key={ index }>
                                            <button type="button" className={ "btn btn-lg btn-block" + ( index === concession_nouvelle_nombre_de_place_pleine_terre ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_concession_nouvelle_nombre_de_place_pleine_terre', payload: index } ) }>{ value.nom }</button>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </>
                }
            </div>
        </div>
        <BottomNav precedent_page={'/Page7'} suivant_page={'/Page9'} />
        </>
    );

}

export default Page8;