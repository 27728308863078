const axios = require('axios').default;

const instance = axios.create({
    baseURL: 'https://liberte.funeralmanager.rip/api.php',
    headers: {
        Authorization: 'ySYM33tpEVQqw0DwexYbONIZKo6Tf1hyjl7swOs0393A9Eh4g6DbNX8miCqB32',
        ContentType: 'application/json',
        'Access-Control-Allow-Credentials':true
    }
});

export const sendSMS = async ( to, code, success_callback, error_callback ) => {

    let text = 'Borne interactive Pompes Funèbres de la Liberté. Voici votre code de connexion : ' + code;

    const response = await instance.post( '/ecran/sendSMS', { to, text } );

    if( response.data && response.data.success && response.data.success === true ){
        if( typeof success_callback !== 'undefined' ){
            success_callback();
        }
    }
    else{
        if( typeof error_callback !== 'undefined' ){
            error_callback();
        }
    }


}

export const sendMAIL = async ( to, code, success_callback, error_callback ) => {

    let text = 'Borne interactive Pompes Funèbres de la Liberté. <br><br>Voici votre code de connexion : <strong>' + code + '</strong>';
    let sujet = 'Borne interactive Pompes Funèbres de la Liberté';

    const response = await instance.post( '/ecran/sendMAIL', { to, text, sujet } );

    if( response.data && response.data.success && response.data.success === true ){
        if( typeof success_callback !== 'undefined' ){
            success_callback();
        }
    }
    else{
        if( typeof error_callback !== 'undefined' ){
            error_callback();
        }
    }

    console.log( response );


}

export const getArticle = async () => {

    const response = await instance.get( '/ecran/getArticle' );
    return response.data

}

export const getCimetiere = async ( longitude, latitude, distance ) => {

    const response = await instance.get( '/cimetiere', {
        params: {
            longitude,
            latitude,
            distance,
        }
    });

    return response.data

}

export const getCommune = async ( longitude, latitude, distance ) => {

    const response = await instance.get( '/commune', {
        params: {
            longitude,
            latitude,
            distance,
        }
    });

    return response.data

}

export const getCrematorium = async ( longitude, latitude, distance ) => {

    const response = await instance.get( '/crematorium', {
        params: {
            longitude,
            latitude,
            distance,
        }
    });

    return response.data

}

export const getHopital = async ( longitude, latitude, distance ) => {

    const response = await instance.get( '/hopital', {
        params: {
            longitude,
            latitude,
            distance,
        }
    });

    return response.data

}

export const getHopitalFromCommuneId = async ( commune_id ) => {

    const response = await instance.get( '/hopital', {
        params: {
            commune_id
        }
    });

    return response.data

}

export const getMaisonDeRepos = async ( longitude, latitude, distance ) => {

    const response = await instance.get( '/maisonderepos', {
        params: {
            longitude,
            latitude,
            distance,
        }
    });

    return response.data

}

export const getEglise = async ( longitude, latitude, distance ) => {

    const response = await instance.get( '/ceremonie', {
        params: {
            longitude,
            latitude,
            distance,
        }
    });

    return response.data

}

export const getEgliseFromCommuneId = async ( commune_id ) => {

    const response = await instance.get( '/ceremonie', {
        params: {
            commune_id
        }
    });

    return response.data

}

export const createDevis = async ( facturation, articles, extra_info ) => {

    const response = await instance.post( '/ecran/createDevis', {
        'pompes-funebres-id': 4,
        articles,
        facturation,
        extra_info
    });

    return response.data

}