import React from "react";
import BottomNav from "./BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {list_devenir_des_cendres, list_lieu_dispersion} from "../misc/listing";
import VignettePhoto from "./sub/VignettePhoto";

function Page6c() {

    const devenir_des_cendres = useSelector( ( state ) => state.devenir_des_cendres );
    const lieu_dispersion = useSelector( ( state ) => state.lieu_dispersion );

    const dispatch = useDispatch();

    return (
        <>
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-2">
                <h1 className="text-center font-gold mt-5 px-5 mx-5">Quel est le devenir des cendres ?</h1>
                <div className="row justify-content-around mb-5 px-5">
                    {
                        list_devenir_des_cendres.map( ( value, index ) => {
                            return <VignettePhoto
                                img={ value.img }
                                key={ index }
                                displayPhoto={ devenir_des_cendres !== 0 }
                                selected={ index === devenir_des_cendres }
                                onClick={ () => dispatch( { type: 'set_devenir_des_cendres', payload: index } ) }
                                nom={ value.label }
                            />
                        } )
                    }
                </div>
                {
                    devenir_des_cendres === 0 && <>
                        <h1 className="text-center font-gold mt-5 px-5 mx-5">Lieu de la dispersion ?</h1>
                        <div className="row justify-content-around mb-5 px-5">
                            {
                                list_lieu_dispersion.map( ( value, index ) => {
                                    return <VignettePhoto
                                        img={ value.img }
                                        key={ index }
                                        selected={ index === lieu_dispersion }
                                        onClick={ () => dispatch( { type: 'set_lieu_dispersion', payload: index } ) }
                                        nom={ value.label }
                                    />
                                } )
                            }
                        </div>
                    </>
                }
            </div>
        </div>
        <BottomNav precedent_page={'/Page6b'} suivant_page={'/Page7'} />
        </>
    );

}

export default Page6c;