import React, {useEffect, useRef, useState} from "react";
import BottomNav from "./BottomNav";
import {useDispatch, useSelector} from "react-redux";
import Keyboard from "react-simple-keyboard";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Page4() {

    //  REDUX
    const demandeur_nom = useSelector( ( state ) => state.demandeur_nom );
    const demandeur_prenom = useSelector( ( state ) => state.demandeur_prenom );
    const demandeur_email = useSelector( ( state ) => state.demandeur_email );
    const demandeur_telephone = useSelector( ( state ) => state.demandeur_telephone );
    const logged_in = useSelector( ( state ) => state.logged_in );

    const dispatch = useDispatch();

    // FORM VALIDATION
    const { register, errors, trigger } = useForm({
        mode: 'onBlur',
    });

    useEffect(() => {
        trigger(['nom', 'prenom', 'email', 'portable']);
    }, []);

    // KEYBOARD
    const [ inputs, setInputs ] = useState({});
    const [ layout, setLayout ] = useState("default")
    const [ inputFocused, setInputFocused ] = useState( null )
    const keyboard = useRef()

    const onChangeAll = inputs => {
        setInputs({ ...inputs });
    };

    const handleShift = () => {
        const newLayoutName = layout === "default" ? "shift" : "default";
        setLayout(newLayoutName);
    };

    const onKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") handleShift();
        if (button === "{enter}") {
            setInputFocused(null);
            inputFocused.blur();
        }
    };

    const onChange = input => {
        if( inputFocused.name === "nom" ){
            dispatch( { type: 'set_demandeur_nom', payload: input } )
        } else if( inputFocused.name === "prenom" ) {
            dispatch( { type: 'set_demandeur_prenom', payload: input } )
        } else if( inputFocused.name === "email" ) {
            dispatch( { type: 'set_demandeur_email', payload: input } )
        } else if( inputFocused.name === "portable" ) {
            dispatch( { type: 'set_demandeur_telephone', payload: input } )
        }
        trigger(inputFocused.name)
    };

    const onChangeInput = event => {
        const inputVal = event.target.value;

        if( event.target.name === "nom" ){
            dispatch( { type: 'set_demandeur_nom', payload: inputVal } )
        } else if( event.target.name === "prenom" ) {
            dispatch( { type: 'set_demandeur_prenom', payload: inputVal } )
        } else if( event.target.name === "email" ) {
            dispatch( { type: 'set_demandeur_email', payload: inputVal } )
        } else if( event.target.name === "portable" ) {
            dispatch( { type: 'set_demandeur_telephone', payload: inputVal } )
        }

        setInputs({
            ...inputs,
            [inputFocused.name]: inputVal
        });

        keyboard.current.setInput(inputVal);
    };

    const getLayout = () => {
        if( inputFocused === null ){
            return {default: []}
        }
        switch ( inputFocused.name ){
            case "email":
                return {
                    default: [
                        '1 2 3 4 5 6 7 8 9 0',
                        '@ a z e r t y u i o p {bksp}',
                        '{lock} q s d f g h j k l m {enter}',
                        '{shift} w x c v b n . - _ {shift}',
                        '{space}',
                        '@gmail.com @hotmail.com @hotmail.fr'
                    ],
                    shift: [
                        '1 2 3 4 5 6 7 8 9 0',
                        '@ A Z E R T Y U I O P {bksp}',
                        '{lock} Q S D F G H J K L M {enter}',
                        '{shift} W X C V B N . - _ {shift}',
                        '{space}',
                        '@gmail.com @hotmail.com @hotmail.fr'
                    ]
                }
            case "portable":
                return {
                    default: [
                        '7 8 9',
                        '4 5 6',
                        '1 2 3',
                        '0',
                        '{bksp} {enter}',
                    ]
                }
            default:
                return {
                    default: [
                        '@ a z e r t y u i o p {bksp}',
                        '{lock} q s d f g h j k l m {enter}',
                        '{shift} w x c v b n . - {shift}',
                        '{space}'
                    ],
                    shift: [
                        '@ A Z E R T Y U I O P {bksp}',
                        '{lock} Q S D F G H J K L M {enter}',
                        '{shift} W X C V B N . - {shift}',
                        '{space}'
                    ]
                }
        }
    }

    return (
        <>
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-5 d-flex flex-column w-75">
                <h1 className="text-center font-gold">Accès au configurateur ?</h1>
                <h4 className="text-center">
                    Afin de réaliser le devis d'obsèques en ligne, veuillez saisir vos coordonnées,<br/>
                    Vous recevrez <b>immédiatement</b> un code d'accès par sms et/ou e-mail.
                </h4>
                <div className="row">
                    <div className="col-12 m-auto">
                        <form autoComplete="off">
                            <div className="form-group">
                                <label htmlFor="nom">Nom</label>
                                <div className="input-group">
                                    <input name="nom" className="form-control"
                                           value={ demandeur_nom }
                                           onFocus={ (event) => setInputFocused( event.target ) }
                                           onChange={ onChangeInput }
                                           ref={register({ required: true })}
                                    />
                                    <div className="input-group-append">
                                        <span className={'input-group-text bg-white text-success ' + ( errors.nom ? ' d-none' : '' ) }>
                                            <FontAwesomeIcon icon={"check"} fixedWidth={true} />
                                        </span>
                                        <span className={'input-group-text bg-white text-warning ' + ( !errors.nom ? ' d-none' : '' ) }>
                                            <FontAwesomeIcon icon={"times"} fixedWidth={true} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="prenom">Prénom</label>
                                <div className="input-group">
                                    <input name="prenom" className="form-control"
                                           value={ demandeur_prenom }
                                           onFocus={ (event) => setInputFocused( event.target ) }
                                           onChange={ onChangeInput }
                                           ref={register({ required: true })}
                                    />
                                    <div className="input-group-append">
                                        <span className={'input-group-text bg-white text-success ' + ( errors.prenom ? ' d-none' : '' ) }>
                                            <FontAwesomeIcon icon={"check"} fixedWidth={true} />
                                        </span>
                                        <span className={'input-group-text bg-white text-warning ' + ( !errors.prenom ? ' d-none' : '' ) }>
                                            <FontAwesomeIcon icon={"times"} fixedWidth={true} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Adresse e-mail</label>
                                <div className="input-group">
                                    <input name="email" className="form-control"
                                           value={ demandeur_email }
                                           onFocus={ (event) => setInputFocused( event.target ) }
                                           onChange={ onChangeInput }
                                           ref={register({
                                               required: true,
                                               pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                           })}
                                    />
                                    <div className="input-group-append">
                                        <span className={'input-group-text bg-white text-success ' + ( errors.email ? ' d-none' : '' ) }>
                                            <FontAwesomeIcon icon={"check"} fixedWidth={true} />
                                        </span>
                                        <span className={'input-group-text bg-white text-warning ' + ( !errors.email ? ' d-none' : '' ) }>
                                            <FontAwesomeIcon icon={"times"} fixedWidth={true} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="portable">Téléphone portable</label>
                                <div className="input-group">
                                    <input name="portable" className="form-control"
                                           value={ demandeur_telephone }
                                           onFocus={ (event) => setInputFocused( event.target ) }
                                           onChange={ onChangeInput }
                                           ref={register({
                                               required: true,
                                               pattern: /^(((\+)33|0|0033)[6|7](\d{2}){4}|((\+)32|0|0032)4[6-9][0-9](\d{2}){3})$/,
                                           })}
                                    />
                                    <div className="input-group-append">
                                        <span className={'input-group-text bg-white text-success ' + ( errors.portable ? ' d-none' : '' ) }>
                                            <FontAwesomeIcon icon={"check"} fixedWidth={true} />
                                        </span>
                                        <span className={'input-group-text bg-white text-warning ' + ( !errors.portable ? ' d-none' : '' ) }>
                                            <FontAwesomeIcon icon={"times"} fixedWidth={true} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={ inputFocused !== null ? "" : "d-none" }>
                    <Keyboard
                        keyboardRef={r => (keyboard.current = r)}
                        layoutName={layout}
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                        inputName={ inputFocused && inputFocused.name }
                        onChangeAll={onChangeAll}
                        layout={getLayout()}
                        display={
                            {
                                '{bksp}': 'supp.',
                                '{enter}': 'entrer',
                                '{shift}': 'maj.',
                                '{lock}': 'ver. maj.',
                                '{space}': ' ',
                            }
                        }
                        buttonTheme={
                            [
                                {
                                    class: "bg-info btn btn-info",
                                    buttons: "{enter}"
                                }
                            ]
                        }
                    />
                </div>
            </div>
        </div>
        <BottomNav precedent_page={'/Page3'} suivant_page={ logged_in ? '/Page6' : '/Page5'} suivant_enable={ !errors.nom && !errors.prenom && !errors.email } />
        </>
    );

}

export default Page4;