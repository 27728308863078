import React, {useState, useRef, useEffect} from "react";
import BottomNav from "./BottomNav";
import { useDispatch, useSelector } from "react-redux";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { useForm } from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

let parent = [
    'mère',
    'père',
    'épouse',
    'époux',
    'grand-mère',
    'grand-père',
    'fille',
    'fils',
    'soeur',
    'frère',
    'belle-mère',
    'beau-père',
    'tante',
    'oncle',
    'beau-frère',
    'belle-soeur',
    'amie',
    'ami',
    'autre'
];

function Page3() {

    const qui = useSelector( ( state ) => state.qui );
    const qui_nom = useSelector( ( state ) => state.qui_nom );
    const qui_prenom = useSelector( ( state ) => state.qui_prenom );
    const dispatch = useDispatch();

    const { register, errors, trigger } = useForm({
        mode: 'onBlur',
    });

    const [ inputs, setInputs ] = useState({});
    const [ layout, setLayout ] = useState("default")
    const [ inputFocused, setInputFocused ] = useState( null )
    const keyboard = useRef()

    useEffect(() => {
        trigger(['nom', 'prenom']);
    }, []);

    const onChangeAll = inputs => {
        setInputs({ ...inputs });
    };

    const handleShift = () => {
        const newLayoutName = layout === "default" ? "shift" : "default";
        setLayout(newLayoutName);
    };

    const onKeyPress = button => {
        if (button === "{shift}" || button === "{lock}") handleShift();
        if (button === "{enter}") {
            setInputFocused(null);
            inputFocused.blur();
        }
    };

    const onChange = input => {
        if( inputFocused.name === 'nom' ){
            dispatch( { type: 'set_qui_nom', payload: input } )
        } else if( inputFocused.name === 'prenom' ){
            dispatch( { type: 'set_qui_prenom', payload: input } )
        }
    };

    const onChangeInput = event => {
        const inputVal = event.target.value;

        if( event.target.name === "nom" ){
            dispatch( { type: 'set_qui_nom', payload: inputVal } )
        } else if( event.target.name === "prenom" ) {
            dispatch( { type: 'set_qui_prenom', payload: inputVal } )
        }

        setInputs({
            ...inputs,
            [inputFocused]: inputVal
        });

        keyboard.current.setInput(inputVal);
    };

    return (
        <>
        <div className="container d-flex justify-content-center flex-column flex-grow-1 overflow-auto">
            <div className="mt-5">
                <form>
                    <h1 className="text-center font-gold mb-5">De qui s'agit-il ?</h1>
                    <div className="row justify-content-around">
                        {
                            parent.map((value, index) => {
                                return (
                                    <div className="col-3 mb-3" key={ index }>
                                        <button type="button" className={ "btn btn-lg btn-block text-capitalize" + ( index === qui ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_qui', payload: index } ) }>{ value }</button>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className="row">
                        <div className="col-6 m-auto">
                            <div className="form-group">
                                <label htmlFor="nom">Son nom</label>
                                <div className="input-group">
                                    <input type="text" name="nom"
                                           value={ qui_nom }
                                           className="form-control"
                                           onFocus={ (event) => setInputFocused( event.target ) }
                                           onChange={ onChangeInput }
                                           ref={register({ required: true })}
                                    />
                                    <div className="input-group-append">
                                        <span className={'input-group-text bg-white text-success ' + ( errors.nom ? ' d-none' : '' ) }>
                                            <FontAwesomeIcon icon={"check"} fixedWidth={true} />
                                        </span>
                                        <span className={'input-group-text bg-white text-warning ' + ( !errors.nom ? ' d-none' : '' ) }>
                                            <FontAwesomeIcon icon={"times"} fixedWidth={true} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="prenom">Son prénom</label>
                                <div className="input-group">
                                    <input type="text" name="prenom"
                                           value={ qui_prenom }
                                           className="form-control"
                                           onFocus={ (event) => setInputFocused( event.target ) }
                                           onChange={ onChangeInput }
                                           ref={register({ required: true })}
                                    />
                                    <div className="input-group-append">
                                        <span className={'input-group-text bg-white text-success ' + ( errors.prenom ? ' d-none' : '' ) }>
                                            <FontAwesomeIcon icon={"check"} fixedWidth={true} />
                                        </span>
                                        <span className={'input-group-text bg-white text-warning ' + ( !errors.prenom ? ' d-none' : '' ) }>
                                            <FontAwesomeIcon icon={"times"} fixedWidth={true} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className={ inputFocused !== null ? "" : "d-none" }>
                <Keyboard
                          keyboardRef={r => (keyboard.current = r)}
                          layoutName={layout}
                          onChange={onChange}
                          onKeyPress={onKeyPress}
                          inputName={ inputFocused && inputFocused.name }
                          onChangeAll={onChangeAll}
                          layout={
                              {
                                  default: [
                                      '@ a z e r t y u i o p {bksp}',
                                      '{lock} q s d f g h j k l m {enter}',
                                      '{shift} w x c v b n . - _ {shift}',
                                      '{space}'
                                  ],
                                  shift: [
                                      '@ A Z E R T Y U I O P {bksp}',
                                      '{lock} Q S D F G H J K L M {enter}',
                                      '{shift} W X C V B N . - _ {shift}',
                                      '{space}'
                                  ]
                              }
                          }
                          display={
                              {
                                  '{bksp}': 'supp.',
                                  '{enter}': 'entrer',
                                  '{shift}': 'maj.',
                                  '{lock}': 'ver. maj.',
                                  '{space}' : ' '
                              }
                          }
                          buttonTheme={
                              [
                                  {
                                      class: "bg-info btn btn-info",
                                      buttons: "{enter}"
                                  }
                              ]
                          }
                />
            </div>
        </div>
        <BottomNav precedent_page={'/Page2'}  suivant_page={'/Page4'} suivant_enable={ qui !== null && !errors.prenom && !errors.nom } />
        </>
    );

}

export default Page3;