
// PAGE 2

export const demande_concerne_list = [
    {
        title: "Un décès survenu",
        article: ['P-22','P-25','P-26','P-85','P-29','P-91'],
        img: '/assets/img/deces_survenu_300_400.jpg',
        disabled: false,
    },
    {
        title: "Un proche en fin de vie",
        article: ['P-22','P-25','P-26','P-85','P-29','P-91'],
        img: '/assets/img/fin_de_vie_300_400.jpg',
        disabled: false,
    },
    {
        title: "Un contrat obsèques",
        article: ['P-22','P-25','P-26','P-85','P-29','P-91'],
        img: '/assets/img/contrat_obseque_300_400.jpg',
        disabled: true,
    }
];

// PAGE 6

export const list_deces_lieu_type = [
    'Hôpital ou Clinique',
    'Ehpad ou Maison de retraite',
    'Domicile',
    'Autre',
];

export const list_obseque_type = [
    {
        label: 'Inhumation',
        article: []
    },
    {
        label: 'Crémation',
        article: []
    },
];

// PAGE 6c

export const list_devenir_des_cendres = [
    {
        label: 'Dispersion',
        img: '/assets/img/6c/jardin_du_souvenir.JPG',
        article: []
    },
    {
        label: 'Inhumation',
        img: '/assets/img/6c/cavurne.jpg',
        article: []
    },
    {
        label: 'Scellement d’Urne',
        img: '/assets/img/6c/scellement_urne_granit.jpg',
        article: []
    },
    {
        label: 'Urne reprise par la famille',
        img: '/assets/img/6c/foret.jpg',
        article: []
    },
    {
        label: 'Dépôt au Columbarium',
        img: '/assets/img/6c/depot_columbarium.jpg',
        article: []
    },
    {
        label: 'Je ne sais pas',
        img: '/assets/img/6c/interrogation.jpg',
        article: []
    },
];

export const list_lieu_dispersion = [
    {
        label: 'Dispersion au Jardin du Souvenir',
        img: '/assets/img/6c/jardin_du_souvenir.JPG',
        article: []
    },
    {
        label: 'Dispersion en pleine nature',
        img: '/assets/img/6c/foret.jpg',
        article: []
    },
    {
        label: 'Dispersion en pleine mer',
        img: '/assets/img/6c/services-bateau.jpg',
        article: []
    },
];

// PAGE 7

export const list_ceremonie_type = [
    {
        label: 'Civile',
        article: []
    },
    {
        label: 'Religieuse',
        article: ['P-19']
    },
    {
        label: 'Pas de cérémonie',
        article: []
    },
];

export const list_ceremonie_civile_type = [
    {
        label: 'Salle de cérémonie',
        seulement_si_cremation: false,
        article: []
    },
    {
        label: 'Crématorium',
        seulement_si_cremation: true,
        article: []
    },
    {
        label: 'Cimetière',
        seulement_si_cremation: false,
        article: []
    },
    {
        label: 'Autre',
        seulement_si_cremation: false,
        article: []
    },
];

export const list_ceremonie_religieuse_type = [
    {
        label: 'Lieu de culte',
        article: [],
    },
    {
        label: 'Autre',
        article: []
    },
];

export const list_ceremonie_no_type = [
    {
        label: 'Commune du cimetière',
        article: []
    },
    {
        label: 'Crématorium',
        article: []
    },
];

// PAGE 8

export const list_concession_existante = [
    'Oui',
    'Non',
];

export const list_concession_existante_type = [
    {
        nom: 'Caveau',
        img: '/assets/img/caveau.jpg',
        article: []
    },
    {
        nom: 'Pleine Terre',
        img: '/assets/img/pleine-terre.jpg',
        article: []
    },
    {
        nom: 'Je ne sais pas',
        img: '/assets/img/interrogation_300_400.jpg',
        article: []
    },
];

export const list_concession_existante_caveau_monument = [
    {
        nom: 'avec monument',
        article: []
    },
    {
        nom: 'sans monument',
        article: ['P-9']
    },
];

export const list_concession_existante_pleine_terre_monument = [
    {
        nom: 'avec monument',
        article: []
    },
    {
        nom: 'sans monument',
        article: []
    },
];

export const list_concession_nouvelle_type = [
    {
        nom: 'Caveau',
        img: '/assets/img/caveau.jpg',
        article: ['P-8']
    },
    {
        nom: 'Pleine Terre',
        img: '/assets/img/pleine-terre.jpg',
        article: []
    },
];

export const list_concession_existante_caveau_monument_ouverture = [
    {
        nom: 'Ouverture sur le dessus',
        img: '/assets/img/ouverture_dessus.jpg',
        article: ['P-10']
    },
    {
        nom: 'Ouverture sur le devant',
        img: '/assets/img/ouverture_face.jpg',
        article: ['P-11']
    },
];

export const list_concession_nouvelle_nombre_de_place_caveau = [
    {
        nom: '1',
        article: ['P-2']
    },
    {
        nom: '2',
        article: ['P-3']
    },
    {
        nom: '3',
        article: ['P-4']
    },
    {
        nom: '4',
        article: ['P-6']
    },
];

export const list_concession_nouvelle_nombre_de_place_pleine_terre = [
    {
        nom: '1',
        article: ['P-12']
    },
    {
        nom: '2',
        article: ['P-13']
    },
    {
        nom: '3',
        article: ['P-14']
    },
    {
        nom: '4',
        article: ['P-15']
    },
];

export const list_concession_existante_nombre_de_place_pleine_terre_avec_monument = [
    {
        nom: '1',
        article: ['P-12']
    },
    {
        nom: '2',
        article: ['P-13']
    },
    {
        nom: '3',
        article: ['P-14']
    },
    {
        nom: '4',
        article: ['P-15']
    },
];

export const list_concession_existante_nombre_de_place_pleine_terre_sans_monument = [
    {
        nom: '1',
        article: ['P-12']
    },
    {
        nom: '2',
        article: ['P-13']
    },
    {
        nom: '3',
        article: ['P-14']
    },
    {
        nom: '4',
        article: ['P-15']
    },
];

// PAGE 10

export const list_recueillement = [
    {
        nom: 'Oui',
        article: ['P-95']
    },
    {
        nom: 'Non',
        article: ['P-96']
    },
];

// PAGE 11

export const list_lieu_de_repos_type = [
    {
        nom: 'Funérarium',
        article: ['P-31','P-33']
    },
    {
        nom: 'Domicile',
        article: []
    },
    {
        nom: 'Je souhaite que le corps reste sur place',
        article: []
    },
];

export const list_lieu_de_repos_funerarium = [
    'Abbeville',
    'Longpré-les-Corps-Saints',
    'Autre funérarium',
];

// PAGE 12

export const list_preparation_du_defunt = [
    {
        nom:'Toilette mortuaire',
        article: []
    },
    {
        nom:'Soins d’hygiène et de présentation',
        article: ['P-41']
    },
    {
        nom:'Je ne sais pas',
        article: []
    },
];

// PAGE ChoixCorbillard

export const list_corbillard = [
    {
        nom: 'Jaguar',
        description: 'Inclus\n- Chauffeur\n- Maître de cérémonie\n- Porteurs\n- Véhicule transport de fleurs et matériels',
        img: '/assets/img/jaguar-presentation-768x436.jpg',
        photo: '',
        prix: 195,
        article: ['P-23']
    },
    {
        nom: 'Mercedes',
        description: 'Inclus\n- Chauffeur\n- Maître de cérémonie\n- Porteurs\n- Véhicule transport de fleurs et matériels',
        img: '/assets/img/mercedes-presentation-768x436.jpg',
        photo: '',
        prix: 176,
        article: ['P-92']
    },
    {
        nom: 'Vivaro',
        description: 'Inclus\n- Chauffeur\n- Porteurs',
        img: '/assets/img/fourgon-presentation-768x436.jpg',
        photo: '',
        prix: 122,
        article: ['P-27']
    },
];

// PAGE 16

export const list_prestations_complementaires = [
    {
        nom: 'Parution dans le Courrier Picard (avis et remerciements)',
        img: '/assets/img/prestationSupplementaire/courrier-picard.jpg',
        article: ['P-21']
    },
    {
        nom: 'Pétales de roses multicolores',
        img: '/assets/img/prestationSupplementaire/petales-de-roses.jpg',
        article: ['P-60']
    },
    {
        nom: 'Retransmission en direct de la cérémonie',
        img: '/assets/img/prestationSupplementaire/livestream.jpg',
        article: ['P-90']
    },
    {
        nom: 'Véhicule de suite avec chauffeur',
        img: '/assets/img/prestationSupplementaire/vehicule-de-suite.jpg',
        article: ['P-24']
    },
    {
        nom: 'Bouquet au funérarium',
        img: '/assets/img/prestationSupplementaire/bouquet.jpg',
        article: []
    },
    {
        nom: 'Médaillon photo porcelaine',
        img: '/assets/img/prestationSupplementaire/photo-porcelaine.jpeg',
        article: ['P-93']
    },
    {
        nom: 'Salle de convivialité, retrouvailles après les obsèques',
        img: '/assets/img/prestationSupplementaire/salle-convivialite.jpg',
        article: ['P-50']
    },
];

// PAGE 16

export const list_prestations_offertes = [
    {
        nom: 'Sonorisation de la cérémonie',
        article: []
    },
    {
        nom: 'Hommage Vidéo en nos funérariums',
        article: []
    },
    {
        nom: 'Registre de condoléances',
        article: []
    },
    {
        nom: 'Pack web & Espace Famille',
        article: []
    },
    {
        nom: 'Photo du défunt sur le cercueil',
        article: []
    },
    {
        nom: 'Plaque d\'identité pour caveau',
        article: []
    },
    {
        nom: 'Arbre du Souvenir',
        article: []
    },
    {
        nom: 'Cagnotte participative pour les frais d\'obsèques',
        article: []
    },
    {
        nom: 'Cérémonie funéraire personnalisée',
        article: []
    },
    {
        nom: 'Musicien pendant la cérémonie',
        article: []
    },
];