import React, {useEffect, useState} from "react";
import {getCimetiere} from "../misc/api";
import {useSelector} from "react-redux";
import ListPlaceByCommune from "./sub/ListPlaceByCommune";
import {useHistory} from "react-router-dom";

function ChoixCimetiere( props ) {

    let history = useHistory();

    const [ listeCimetiere, setListeCimetiere ] = useState( [] );

    const coordonnee = useSelector( ( state ) => state.coordonnee );

    const getAroundCimetiere = () => {
        getCimetiere( coordonnee.longitude, coordonnee.latitude, 10 )
            .then( response => {
                if( response.success ){
                    setListeCimetiere( response.data )
                }
            })
            .catch( error => console.log( error ) );
    }

    useEffect( getAroundCimetiere, []);

    return (
        <>
            <ListPlaceByCommune liste={ listeCimetiere } setpoi={ history.location.state } />
        </>
    );

}

export default ChoixCimetiere;