import React from "react";
import BottomNav from "./BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {list_prestations_complementaires} from "../misc/listing";
import VignettePhoto from "./sub/VignettePhoto";

function ChoixPrestationComplementaire() {

    const prestation_complementaire = useSelector( state => state.prestation_complementaire );

    let dispatch = useDispatch();

    return (
        <>
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-2">
                <h1 className="text-center font-gold mt-5 mb-4">Prestations complémentaires & Services</h1>
                <div className="row mb-5 justify-content-center">
                    {
                        list_prestations_complementaires.map((value, index) => {
                            let present = prestation_complementaire.indexOf(index) > -1;
                            return (
                                <VignettePhoto
                                    containerClass={ 'col-3 mb-3' }
                                    img={ value.img }
                                    heightPhoto={ '200px' }
                                    key={ index }
                                    selected={ present }
                                    onClick={ () => dispatch( { type: 'set_prestation_complementaire', payload: { index, type: ( present ? 'remove' : 'add' ) } } ) }
                                    nom={ value.nom }
                                />
                            );
                        })
                    }
                </div>
            </div>
        </div>
        <BottomNav precedent_page={ '/ChoixCorbillard' } suivant_page={'/ChoixPrestationOffertes'} />
        </>
    );

}

export default ChoixPrestationComplementaire;