import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {createDevis} from "../misc/api";

function ObtenirMonDevis() {

    const list_choix_articles = useSelector( state => state.list_choix_articles );
    const demandeur_nom = useSelector( state => state.demandeur_nom );
    const demandeur_prenom = useSelector( state => state.demandeur_prenom );
    const demandeur_email = useSelector( state => state.demandeur_email );
    const demandeur_telephone = useSelector( state => state.demandeur_telephone );
    const qui = useSelector( state => state.qui );
    const qui_nom = useSelector( state => state.qui_nom );
    const qui_prenom = useSelector( state => state.qui_prenom );
    const devis_reference = useSelector( state => state.devis_reference );

    const dispatch = useDispatch();

    let reset = _ => {
        window.location.href = '/';
    }

    let obtenirMonDevis = _ => {

        console.log( list_choix_articles );

        let facturation = {
            nom: demandeur_nom,
            prenom: demandeur_prenom,
            email: demandeur_email,
            gsm: demandeur_telephone,
        }

        let extra_info = {
            defunt: {
                nom_defunt: qui_nom,
                prenom_defunt: qui_prenom,
                parente_defunt: qui
            }
        }

        createDevis( facturation, list_choix_articles, extra_info )
            .then( response => {
                if( response.success ){
                    dispatch( { type: 'set_devis_reference', payload: response.data.reference } )
                }
            })
            .catch( error => console.log( error ) );

    }

    return (
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-5">
                {
                    devis_reference === null ?
                        <>
                            <h1 className="text-center font-gold mb-5 mt-5">Obtenez votre devis personalisé<br/>Gratuit et sans engagement</h1>
                            <p className="text-center h4 px-5">
                                En cliquant sur le bouton "Obtenir mon devis" vous recevrez <strong>immédiatement</strong><br/>votre devis obsèques.<br/><br/>
                                En cas de besoin, vous pouvez faire appel à nos conseillers disponibles<br/>par téléphone ou par mail.<br/><br/>
                            </p>
                            <p className="text-center h4 pb-5" style={{'fontSize': '1.2rem'}}>
                                En cliquant sur le bouton "Obtenir mon devis" j'accepte que les informations saisies soient<br/>
                                exploitées dans le cadre de la demande de devis et de la relation commerciale qui peut en découler.<br/><br/>
                                Pour connaître et exercer vos droits, veuillez consulter notre politique de confidentialité.
                            </p>
                            <p className="text-center h4 px-5 mt-5">
                                <button className="btn btn-warning btn-lg mt-2" onClick={ () => obtenirMonDevis() }>Obtenir mon devis</button>
                            </p>
                        </> :
                        <>
                            <h1 className="text-center font-gold mb-5 mt-5">Votre devis a été envoyé sur votre adresse email.</h1>
                            <h3 className="text-center mt-5">
                                La référénce de votre devis est : <strong>{ devis_reference }</strong>
                            </h3>
                            <p className="text-center h4 px-5 mt-5">
                                <button className="btn btn-warning btn-lg mt-2" onClick={ () => reset() }>Terminer ma session</button>
                            </p>
                        </>
                }
            </div>
        </div>
    );

}

export default ObtenirMonDevis;