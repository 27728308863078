import React from "react";
import NumberFormat from "react-number-format";

function VignetteArticle( props ) {

    return (

        <div className="col-4 mb-3">
            <div className={ "card" + ( props.selected ? " active" : "" ) } onClick={ props.onClick }>
                {
                    !!props.photo ?
                        <img className="card-img-top" src={ props.photo } alt={ props.nom } style={{height: '250px', objectFit: 'contain', backgroundColor:'white'}} />
                        :
                        <div className="d-flex justify-content-center align-content-center bg-white" style={{height: '250px'}}>
                            <h5 className="align-self-center text-dark text-center">{ props.photo_replace_text }</h5>
                        </div>
                }
                <div className="card-body">
                    <h5 className="card-title" style={{ height: props.height ?? '80px' }}>{ props.nom }</h5>
                    <div className={'text-right'}>
                        <h3>
                            <NumberFormat className={'badge' + ( props.selected ? " badge-light" : " badge-warning" ) } value={ Number( props.prix ) } displayType={'text'} thousandSeparator={" "} prefix={'€ '} suffix={' TTC'} decimalScale={2} fixedDecimalScale={true} decimalSeparator={","} />
                        </h3>
                    </div>
                    {
                        props.description && props.description.length && <>{ props.description.split("\n").map( ( item, index) => <div key={ index }>{item}<br/></div>) }</>
                    }
                </div>
            </div>
        </div>

    )

}

export default VignetteArticle;