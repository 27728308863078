import React from "react";
import BottomNav from "./BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {list_prestations_offertes} from "../misc/listing";

function ChoixPrestationOffertes() {

    const prestation_offertes = useSelector( state => state.prestation_offertes );

    let dispatch = useDispatch();

    return (
        <>
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-2">
                <h1 className="text-center font-gold mt-5 mb-4">Prestations & Services offerts</h1>
                <div className="row mb-5 justify-content-center">
                    {
                        list_prestations_offertes.map((value, index) => {
                            let present = prestation_offertes.indexOf(index) > -1;
                            return (
                                <div className="col-8 mx-5 mb-3" key={ index }>
                                    <button type="button" className={ "btn btn-lg btn-block text-capitalize" + ( present ? ' btn-outline-success' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_prestation_offertes', payload: { index, type: ( present ? 'remove' : 'add' ) } } ) }>{ value.nom }</button>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
        <BottomNav precedent_page={ '/ChoixPrestationComplementaire' } suivant_page={'/ObtenirMonDevis'} />
        </>
    );

}

export default ChoixPrestationOffertes;