import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import Map from "./Map";
import {useDispatch} from "react-redux";

function ListPlace( props ) {

    let history = useHistory();

    let dispatch = useDispatch();

    const [ selectedPlace, setSelectedPlace ] = useState( null );

    const confirmSelectedPlace = ( item ) => {
        if( typeof props.setpoi !== "undefined" ){
            dispatch( { type: props.setpoi, payload: item } )
        }
        history.goBack();
    }

    return (
        <>
            <div className="flex-grow-1 d-flex">
                <div className="mt-4 flex-grow-1 overflow-hidden" style={{ height: '832px'}}>
                    <div className="m-2 d-flex flex-row">
                        <div className="col-4">
                            <ul className="list-group overflow-auto" style={{height: '795px'}}>
                                {
                                    props.liste.map( ( item, index ) => {
                                        return item.villes ?
                                            <li key={ item.id } className="list-group-item text-capitalize" onClick={ () => setSelectedPlace( item ) }>
                                                <b>{ item.postal_code + ' ' + item.name }</b><br />
                                                {
                                                    item.villes && item.villes.length > 0 && item.villes.map( ( value, index ) => {
                                                        return <span>{ value }</span>
                                                    })
                                                }
                                                </li>
                                            :
                                            <li key={ item.id } className="list-group-item text-capitalize" onClick={ () => setSelectedPlace( item ) }>
                                                <b>{ item.name }</b><br />
                                                { item.postal_code + ' ' + item.city }</li>
                                    })
                                }
                            </ul>
                        </div>
                        <div className="col-8">
                            <div className={'card'} style={{height: '795px'}}>
                                <div className="card-body">
                                    {
                                        selectedPlace !== null && <div>
                                            <button className="float-right btn btn-warning"
                                                onClick={ () => confirmSelectedPlace( selectedPlace ) }
                                            >
                                                <FontAwesomeIcon icon={'check'} />
                                                &nbsp;&nbsp;Choisir ce lieu
                                            </button>
                                            <h3>{ selectedPlace.name }</h3>
                                            <h6>
                                                { selectedPlace.street }<br/>
                                                { selectedPlace.postal_code + ' ' + selectedPlace.city }
                                            </h6>
                                            <Map
                                                longitude={ selectedPlace.longitude }
                                                latitude={ selectedPlace.latitude }
                                                zoom={16}
                                                googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCVXRDwCBrxEiu9fSdl5cuL8FEa38Ac5Aw"
                                                loadingElement={<div style={{ height: `100%` }} />}
                                                containerElement={<div style={{ height: `400px` }} />}
                                                mapElement={<div style={{ height: `100%` }} />}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <footer className="flex-shrink-1">
                <div className="py-3">
                    <div className="d-flex">
                        <div className="col-12">
                            <button onClick={ history.goBack } className={'btn btn-warning btn-lg'}>
                                <FontAwesomeIcon icon={"chevron-left"} />&nbsp;&nbsp;
                                Retour
                            </button>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )

}

export default ListPlace