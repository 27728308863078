import React from "react";
import BottomNav from "./BottomNav";
import {useDispatch, useSelector} from "react-redux";
import POI from "./sub/POI";

function Page9() {

    const concession_existante = useSelector( ( state ) => state.concession_existante );
    const concession_option_gravure = useSelector( ( state ) => state.concession_option_gravure );
    const concession_option_remise_a_neuf_gravure = useSelector( ( state ) => state.concession_option_remise_a_neuf_gravure );
    const concession_option_monument_temporaire = useSelector( ( state ) => state.concession_option_monument_temporaire );
    const cimetiere_poi = useSelector( ( state ) => state.cimetiere_poi );
    const dispatch = useDispatch();


    return (
        <>
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-2">
                <h1 className="text-center font-gold mt-5 mb-3">{ concession_existante === 0 ? 'Dans quelle ville se situe cette concession ?' : 'Dans quelle ville souhaitez-vous une concession ?' }</h1>
                <div className="row justify-content-around mb-5">
                    <div className="col-12">
                        {
                            <POI poi={ cimetiere_poi } route={ '/ChoixCimetiere' } action_type={ 'set_cimetiere_poi' } title={'Cimetière non-sélectionné'} button_select={'Choisissez un cimetière'} button_update={'Modifiez ce cimetière'} />
                        }
                    </div>
                </div>
                <h3 className="text-center font-gold mt-5">Option(s) disponible(s)</h3>
                <h5 className="text-center mb-3">A valider auprès du conseiller</h5>
                <div className="row">
                    <div className="col-4 mb-3">
                        <button type="button" className={ "btn btn-lg btn-block" + ( concession_option_gravure ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_concession_option_gravure', payload: !concession_option_gravure } ) }>Gravure</button>
                    </div>
                    <div className="col-4 mb-3">
                        <button type="button" className={ "btn btn-lg btn-block" + ( concession_option_remise_a_neuf_gravure ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_concession_option_remise_a_neuf_gravure', payload: !concession_option_remise_a_neuf_gravure } ) }>Remise à neuf de la gravure existante</button>
                    </div>
                    <div className="col-4 mb-3">
                        <button type="button" className={ "btn btn-lg btn-block" + ( concession_option_monument_temporaire ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_concession_option_monument_temporaire', payload: !concession_option_monument_temporaire } ) }>Monument temporaire</button>
                    </div>
                </div>
            </div>
        </div>
        <BottomNav precedent_page={'/Page8'} suivant_page={'/Page11'} />
        </>
    );

}

export default Page9;