import React, {useEffect, useState} from "react";
import {getCrematorium} from "../misc/api";
import {useSelector} from "react-redux";
import ListPlaceByCommune from "./sub/ListPlaceByCommune";
import {useHistory} from "react-router-dom";

function ChoixCrematorium( props ) {

    let history = useHistory();

    const [ listeCrematorium, setListeCrematorium ] = useState( [] );

    const coordonnee = useSelector( ( state ) => state.coordonnee );

    const getAroundCrematorium = () => {
        getCrematorium( coordonnee.longitude, coordonnee.latitude, 100 )
            .then( response => {
                if( response.success ){
                    setListeCrematorium( response.data )
                }
            })
            .catch( error => console.log( error ) );
    }

    useEffect( getAroundCrematorium, []);

    return (
        <>
            <ListPlaceByCommune liste={ listeCrematorium } setpoi={ history.location.state } />
        </>
    );

}

export default ChoixCrematorium;