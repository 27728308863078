import React from "react";
import BottomNav from "./BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import VignetteArticle from "./sub/VignetteArticle";
import {useHistory} from "react-router-dom";

function Page13() {

    let history = useHistory();

    const number_on_page = 3;

    const offset_list_embleme = useSelector( ( state ) => state.offset_list_embleme );
    const embleme_selectionne = useSelector( ( state ) => state.embleme_selectionne );
    const list_embleme = useSelector( ( state ) => state.list_embleme );

    const dispatch = useDispatch();

    const filtered_list_embleme = Object.values(list_embleme).sort((a, b) => a.prix_ttc - b.prix_ttc );;

    const count_list_embleme = filtered_list_embleme.length;

    const final_list_embleme = filtered_list_embleme.slice(offset_list_embleme, offset_list_embleme + number_on_page);

    return (
        <>
            <div className="container flex-grow-1 d-flex justify-content-center">
                <div className="mt-2 d-flex flex-grow-1 flex-column">
                    <h1 className="text-center font-gold mt-5">Choix de l'emblème</h1>
                    <div className="d-flex flex-grow-1">
                        <div className="row mb-5 align-content-around flex-grow-1">
                            {
                                final_list_embleme.map((value, index) => {
                                    return (
                                        <VignetteArticle
                                            key={ index }
                                            selected={ embleme_selectionne !== null && embleme_selectionne.code === value.code }
                                            onClick={ () => {
                                                dispatch({type: 'set_embleme_selectionne', payload: value });
                                                history.goBack();
                                            } }
                                            photo={ value.photo }
                                            photo_replace_text={ 'Pas de photo pour cet emblème' }
                                            nom={ value.nom }
                                            prix={ value.prix_ttc }
                                            height={ '40px' }
                                        />
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="d-flex pb-4">
                        <div className="col-4">
                            {
                                offset_list_embleme > 0 && <button className="btn btn-primary btn-lg" onClick={ () => dispatch({ type: 'set_offset_list_embleme', payload: (offset_list_embleme - number_on_page) }) }>
                                    <FontAwesomeIcon icon={"chevron-left"} />&nbsp;&nbsp;
                                    emblemes précédents
                                </button>
                            }
                        </div>
                        <div className="col-4 text-center">
                            <span className="btn btn-outline-dark btn-lg" >Page { ( offset_list_embleme / number_on_page ) + 1 }  / { Math.ceil( count_list_embleme / number_on_page )  }</span>
                        </div>
                        <div className="col-4 text-right">
                            {
                                offset_list_embleme + number_on_page < count_list_embleme && <button className="btn btn-primary btn-lg" onClick={ () => dispatch({ type: 'set_offset_list_embleme', payload: (offset_list_embleme + number_on_page) }) }>
                                    emblemes suivants&nbsp;&nbsp;
                                    <FontAwesomeIcon icon={"chevron-right"} />
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        <BottomNav precedent_page={'/Page14'} suivant_enable={ false } />
        </>
    );

}

export default Page13;