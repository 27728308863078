import React, {useEffect, useState} from 'react'
import {withGoogleMap, GoogleMap, Marker, withScriptjs} from "react-google-maps"

// https://www.npmjs.com/package/@react-google-maps/api

const Map = withScriptjs(withGoogleMap((props) => {

    const [cordinates , setCordinates] = useState({ lat: 10, lng: 10 } );

    useEffect(() => {
        setCordinates({ lat: props.latitude, lng: props.longitude });
    }, [props.latitude, props.longitude ])

    return (
        <GoogleMap
            defaultZoom={ props.zoom ?? 8 }
            center={cordinates}
            options={{
                fullscreenControl: false,
                mapTypeControl: false,
                panControl: false,
                streetViewControl: false,
            }}
        >
            <Marker position={{ lat: props.latitude, lng: props.longitude }} />
        </GoogleMap>
    )

    }
))

export default Map;