import React from "react";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import NumberFormat from 'react-number-format';

function Devis( props ) {

    let history = useHistory();

    const categorie_normalise = useSelector( ( state ) => state.categorie_normalise );
    const list_choix_articles = useSelector( ( state ) => state.list_choix_articles );

    return (
        <>
        <div className="container flex-grow-1 d-flex">
            <div className="mt-2 flex-row flex-grow-1">
                <div className="col-12">
                    <h2 className="mt-3">Votre Devis</h2>
                    {
                        categorie_normalise.map( ( item, index ) => {

                            let article_pour_cette_categorie = list_choix_articles.filter( item2 => item2.normalise_categorie_id === item.id );

                            if( article_pour_cette_categorie.length ){

                                return <div key={'CAT-'+index}>
                                    <h5>{ item.label }</h5>
                                    <table className={'table table-bordered table-sm'}>
                                    <thead>
                                    <tr>
                                        <th>Article</th>
                                        <th width="10%" className={'text-center'}>Courants</th>
                                        <th width="10%" className={'text-center'}>Optionnels</th>
                                        <th width="10%" className={'text-center'}>Avancés</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        article_pour_cette_categorie.map( ( item2, index2 ) => {
                                            return <tr key={'ART-'+index2}>
                                                <td>{ item2.nom }</td>
                                                <td className={'text-right'}>{ item2.normalise_categorie_type === 'C' ? <NumberFormat value={ Number( item2.prix_ttc ) } displayType={'text'} thousandSeparator={" "} prefix={'€ '} decimalScale={2} fixedDecimalScale={true} decimalSeparator={","} /> : '' }</td>
                                                <td className={'text-right'}>{ item2.normalise_categorie_type === 'O' ? <NumberFormat value={ Number( item2.prix_ttc ) } displayType={'text'} thousandSeparator={" "} prefix={'€ '} decimalScale={2} fixedDecimalScale={true} decimalSeparator={","} /> : '' }</td>
                                                <td className={'text-right'}>{ item2.normalise_categorie_type === 'A' ? <NumberFormat value={ Number( item2.prix_ttc ) } displayType={'text'} thousandSeparator={" "} prefix={'€ '} decimalScale={2} fixedDecimalScale={true} decimalSeparator={","} /> : '' }</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                    </table>
                                </div>

                            }

                            return null;

                        })
                    }
                    <h3 className={'text-right'}>Total: <NumberFormat value={ list_choix_articles.reduce((a, b) => +a + +b.prix_ttc, 0) } displayType={'text'} thousandSeparator={" "} prefix={'€ '} decimalScale={2} fixedDecimalScale={true} decimalSeparator={","} /></h3>
                </div>
            </div>
        </div>
            <footer className="flex-shrink-1">
                <div className="container py-3">
                    <div className="d-flex">
                        <div className="col-12">
                            <button onClick={ history.goBack } className={'btn btn-warning btn-lg'}>
                                <FontAwesomeIcon icon={"chevron-left"} />&nbsp;&nbsp;
                                Retour
                            </button>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );

}

export default Devis;