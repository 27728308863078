import React, {useEffect, useState} from "react";
import {getCommune} from "../misc/api";
import {useSelector} from "react-redux";
import ListPlace from "./sub/ListPlace";
import {useHistory} from "react-router-dom";

function ChoixHopital( props ) {

    let history = useHistory();

    const [ listeCommune, setListeCommune ] = useState( [] );

    const coordonnee = useSelector( ( state ) => state.coordonnee );

    const getAroundCommune = () => {
        getCommune( coordonnee.longitude, coordonnee.latitude, 50 )
            .then( response => {
                if( response.success ){
                    setListeCommune( response.data )
                }
            })
            .catch( error => console.log( error ) );
    }

    useEffect( getAroundCommune, []);

    return (
        <>
            <ListPlace liste={ listeCommune } setpoi={ history.location.state } />
        </>
    );

}

export default ChoixHopital;