import React from "react";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";

function ListeArticle( props ) {

    let history = useHistory();

    const list_article = useSelector( ( state ) => state.list_article );
    const list_cercueil = useSelector( ( state ) => state.list_cercueil );
    const list_embleme = useSelector( ( state ) => state.list_embleme );
    const list_capiton = useSelector( ( state ) => state.list_capiton );

    return (
        <>
        <div className="container flex-grow-1 d-flex">
            <div className="mt-2 flex-row flex-grow-1">
                <div className="col-12">
                    <h2 className="mt-3">Article</h2>
                    <table className={'table table-bordered table-sm'}>
                        <thead>
                        <tr>
                            <th width="2%">Code</th>
                            <th>Article</th>
                            <th width="10%" className={'text-center'}>Prix Base</th>
                            <th width="10%" className={'text-center'}>Prix</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            Object.values(list_article).map( ( item, index ) => {
                                return <tr key={ index }>
                                    <td>{ item.code }</td>
                                    <td>{ item.nom }</td>
                                    <td>
                                        <NumberFormat value={ item.prix_base ?? 0 } displayType={'text'} thousandSeparator={" "} prefix={'€ '} decimalScale={2} fixedDecimalScale={true} decimalSeparator={"."} />
                                    </td>
                                    <td>
                                        <NumberFormat value={ item.prix ?? 0 } displayType={'text'} thousandSeparator={" "} prefix={'€ '} decimalScale={2} fixedDecimalScale={true} decimalSeparator={"."} />
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                    <h2 className="mt-3">Cerceuil</h2>
                    <table className={'table table-bordered table-sm'}>
                        <thead>
                        <tr>
                            <th width="2%">Code</th>
                            <th>Article</th>
                            <th width="10%" className={'text-center'}>Prix Base</th>
                            <th width="10%" className={'text-center'}>Prix</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            Object.values(list_cercueil).map( ( item, index ) => {
                                return <tr key={ index }>
                                    <td>{ item.code }</td>
                                    <td>{ item.nom }</td>
                                    <td>
                                        <NumberFormat value={ item.prix_base ?? 0 } displayType={'text'} thousandSeparator={" "} prefix={'€ '} decimalScale={2} fixedDecimalScale={true} decimalSeparator={"."} />
                                    </td>
                                    <td>
                                        <NumberFormat value={ item.prix ?? 0 } displayType={'text'} thousandSeparator={" "} prefix={'€ '} decimalScale={2} fixedDecimalScale={true} decimalSeparator={"."} />
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                    <h2 className="mt-3">Capiton</h2>
                    <table className={'table table-bordered table-sm'}>
                        <thead>
                        <tr>
                            <th width="2%">Code</th>
                            <th>Article</th>
                            <th width="10%" className={'text-center'}>Prix Base</th>
                            <th width="10%" className={'text-center'}>Prix</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            Object.values(list_capiton).map( ( item, index ) => {
                                return <tr key={ index }>
                                    <td>{ item.code }</td>
                                    <td>{ item.nom }</td>
                                    <td>
                                        <NumberFormat value={ item.prix_base ?? 0 } displayType={'text'} thousandSeparator={" "} prefix={'€ '} decimalScale={2} fixedDecimalScale={true} decimalSeparator={"."} />
                                    </td>
                                    <td>
                                        <NumberFormat value={ item.prix ?? 0 } displayType={'text'} thousandSeparator={" "} prefix={'€ '} decimalScale={2} fixedDecimalScale={true} decimalSeparator={"."} />
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                    <h2 className="mt-3">Embleme</h2>
                    <table className={'table table-bordered table-sm'}>
                        <thead>
                        <tr>
                            <th width="2%">Code</th>
                            <th>Article</th>
                            <th width="10%" className={'text-center'}>Prix Base</th>
                            <th width="10%" className={'text-center'}>Prix</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            Object.values(list_embleme).map( ( item, index ) => {
                                return <tr key={ index }>
                                    <td>{ item.code }</td>
                                    <td>{ item.nom }</td>
                                    <td>
                                        <NumberFormat value={ item.prix_base ?? 0 } displayType={'text'} thousandSeparator={" "} prefix={'€ '} decimalScale={2} fixedDecimalScale={true} decimalSeparator={"."} />
                                    </td>
                                    <td>
                                        <NumberFormat value={ item.prix ?? 0 } displayType={'text'} thousandSeparator={" "} prefix={'€ '} decimalScale={2} fixedDecimalScale={true} decimalSeparator={"."} />
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <footer className="flex-shrink-1">
            <div className="container py-3">
                <div className="d-flex">
                    <div className="col-12">
                        <button onClick={ history.goBack } className={'btn btn-warning btn-lg'}>
                            <FontAwesomeIcon icon={"chevron-left"} />&nbsp;&nbsp;
                            Retour
                        </button>
                    </div>
                </div>
            </div>
        </footer>
        </>
    );

}

export default ListeArticle;