import React from "react";
import {useHistory} from "react-router-dom";

function POI( props ) {

    let history = useHistory();

    return (
        <div className="card">
            <div className="row no-gutters">
                <div className="flex-grow-1">
                    <div className="card-body">
                        {
                            props.poi === null ?
                                <>
                                    <h5 className="card-title">{ props.title }</h5>
                                </>
                                :
                                props.poi.villes ?
                                    <>
                                        <h5 className="card-title">{ props.poi.postal_code + ' ' + props.poi.name }</h5>
                                    </>
                                    :
                                    <>
                                        <h5 className="card-title">{ props.poi.name }</h5>
                                        <p className="card-text">
                                            { props.poi.street }<br/>
                                            { props.poi.postal_code + ' ' + props.poi.city }
                                        </p>
                                    </>
                        }
                        {
                            props.afficher_button_modifier && <button className="btn btn-info" onClick={ () => history.push( props.route, props.action_type ) }>{ props.poi === null ? props.button_select : props.button_update }</button>
                        }
                    </div>
                </div>
                <div className="">
                    {
                        props.poi && props.poi.streetview_url && <img style={{maxHeight: '200px'}} alt="poi" src={ props.poi.streetview_url.replace('AIzaSyB3WdGvSwzDQKCfayj_ofDpXHzYP27CZw8', 'AIzaSyCVXRDwCBrxEiu9fSdl5cuL8FEa38Ac5Aw') } />
                    }
                    {
                        props.poi && props.poi.streetview_url === null && props.poi.static_map_url && <img alt="poi" style={{maxHeight: '200px'}} src={ props.poi.static_map_url.replace('AIzaSyB3WdGvSwzDQKCfayj_ofDpXHzYP27CZw8', 'AIzaSyCVXRDwCBrxEiu9fSdl5cuL8FEa38Ac5Aw') } />
                    }
                </div>
            </div>
        </div>
    )

}

POI.defaultProps = {
    title: 'Le lieu n\'a pas encore été choisi',
    button_select: 'Choisir un lieu',
    button_update: 'Modifier le lieu',
    afficher_button_modifier: true,
}

export default POI