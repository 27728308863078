import React from "react";
import BottomNav from "./BottomNav";
import {useSelector} from "react-redux";
import POI from "./sub/POI";

function Page6b() {

    const crematorium_poi = useSelector( ( state ) => state.crematorium_poi );

    return (
        <>
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-2">
                <h1 className="text-center font-gold mt-5 px-5 mx-5">Quel crématorium ?</h1>
                <div className="row justify-content-around mb-5">
                    <div className="col-12">
                        {
                            <POI poi={ crematorium_poi } route={ '/ChoixCrematorium' } action_type={ 'set_crematorium_poi' } title={'Crématorium non-sélectionné'} button_select={'Choisissez un crématorium'} button_update={'Modifiez ce crématorium'} />
                        }
                    </div>
                </div>
            </div>
        </div>
        <BottomNav precedent_page={'/Page6'} suivant_page={'/Page6c'} />
        </>
    );

}

export default Page6b;