import {
    list_ceremonie_type,
    list_obseque_type,
    demande_concerne_list,
    list_prestations_complementaires,
    list_corbillard,
    list_concession_nouvelle_nombre_de_place_caveau,
    list_concession_nouvelle_nombre_de_place_pleine_terre,
    list_concession_nouvelle_type,
    list_concession_existante_type,
    list_lieu_de_repos_type,
    list_recueillement,
    list_preparation_du_defunt,
    list_concession_existante_caveau_monument,
    list_concession_existante_caveau_monument_ouverture,
    list_concession_existante_pleine_terre_monument,
    list_concession_existante_nombre_de_place_pleine_terre_avec_monument,
    list_concession_existante_nombre_de_place_pleine_terre_sans_monument
} from "./listing";


export const updateChoixArticle = (state) => {

    let list_article = state.list_article;
    let final_choix_article = [];

    if (state.demande_concerne !== null) {

        let new_article = demande_concerne_list[state.demande_concerne].article.map(item => {
            return list_article[item]
        }).filter(Boolean);

        final_choix_article = [...final_choix_article, ...new_article];

    }

    if (state.obseque_type !== null) {
        let new_article = list_obseque_type[state.obseque_type].article.map(item => {
            return list_article[item]
        }).filter(Boolean);
        final_choix_article = [...final_choix_article, ...new_article];
    }

    if (state.ceremonie_type !== null) {

        let new_article = list_ceremonie_type[state.ceremonie_type].article.map(item => {
            return list_article[item];
        }).filter(Boolean);

        final_choix_article = [...final_choix_article, ...new_article];
    }

    // PAGE 8

    // Seul dieu sait comment ca se passe ci-dessous.
    // Faire un backup avant modification

    if (state.concession_existante === 1) { // Avez-vous une concession existante? NON

        if( state.concession_nouvelle_type !== null ){
            let new_article = list_concession_nouvelle_type[state.concession_nouvelle_type].article.map(item => {
                return list_article[item];
            }).filter(Boolean);

            final_choix_article = [...final_choix_article, ...new_article];
        }

        if (state.concession_nouvelle_type === 0 && state.concession_nouvelle_nombre_de_place_caveau !== null ) { // Type de concession existante => Caveau

            let new_article = list_concession_nouvelle_nombre_de_place_caveau[state.concession_nouvelle_nombre_de_place_caveau].article.map(item => {
                return list_article[item];
            }).filter(Boolean);

            final_choix_article = [...final_choix_article, ...new_article];

        } else if (state.concession_nouvelle_type === 1 && state.concession_nouvelle_nombre_de_place_pleine_terre !== null) { // Type de concession existante => Pleine Terre

            let new_article = list_concession_nouvelle_nombre_de_place_pleine_terre[state.concession_nouvelle_nombre_de_place_pleine_terre].article.map(item => {
                return list_article[item];
            }).filter(Boolean);

            final_choix_article = [...final_choix_article, ...new_article];

        }

    } else if ( state.concession_existante === 0 ) { // Avez-vous une concession existante? OUI

        if( state.concession_existante_type !== null ){
            let new_article = list_concession_existante_type[state.concession_existante_type].article.map(item => {
                return list_article[item];
            }).filter(Boolean);

            final_choix_article = [...final_choix_article, ...new_article];
        }

        if ( state.concession_existante_type === 0 && state.concession_existante_caveau_monument !== null ) { // Type : Caveau

            let new_article = list_concession_existante_caveau_monument[state.concession_existante_caveau_monument].article.map(item => {
                return list_article[item];
            }).filter(Boolean);

            final_choix_article = [...final_choix_article, ...new_article];

            if( state.concession_existante_caveau_monument === 0 && state.concession_existante_caveau_monument_ouverture !== null ){ // Avec monument

                let new_article = list_concession_existante_caveau_monument_ouverture[state.concession_existante_caveau_monument_ouverture].article.map(item => {
                    return list_article[item];
                }).filter(Boolean);

                final_choix_article = [...final_choix_article, ...new_article];

            }

        } else if ( state.concession_existante_type === 1 && state.concession_existante_pleine_terre_monument !== null ) { // Type : Plein terre

            let new_article = list_concession_existante_pleine_terre_monument[state.concession_existante_pleine_terre_monument].article.map(item => {
                return list_article[item];
            }).filter(Boolean);

            final_choix_article = [...final_choix_article, ...new_article];

            if( state.concession_existante_pleine_terre_monument === 0 && state.concession_existante_nombre_de_place_pleine_terre_avec_monument !== null ){

                let new_article = list_concession_existante_nombre_de_place_pleine_terre_avec_monument[state.concession_existante_nombre_de_place_pleine_terre_avec_monument].article.map(item => {
                    return list_article[item];
                }).filter(Boolean);

                final_choix_article = [...final_choix_article, ...new_article];

            } else if( state.concession_existante_pleine_terre_monument === 1 && state.concession_existante_nombre_de_place_pleine_terre_sans_monument !== null ){

                let new_article = list_concession_existante_nombre_de_place_pleine_terre_sans_monument[state.concession_existante_nombre_de_place_pleine_terre_sans_monument].article.map(item => {
                    return list_article[item];
                }).filter(Boolean);

                final_choix_article = [...final_choix_article, ...new_article];

            }

        }

    }

    if (state.concession_option_monument_temporaire) {

        final_choix_article = [...final_choix_article, list_article['P-86']];

    }

    // PAGE 11

    if (state.lieu_de_repos_type === 0) {

        let new_article = list_lieu_de_repos_type[state.lieu_de_repos_type].article.map(item => {
            return list_article[item];
        }).filter(Boolean);

        final_choix_article = [...final_choix_article, ...new_article];

        if (state.recueillement !== null) {

            let new_article = list_recueillement[state.recueillement].article.map(item => {
                return list_article[item];
            }).filter(Boolean);

            final_choix_article = [...final_choix_article, ...new_article];

        }

    } else if (state.lieu_de_repos_type === 1) {

        if (state.deces_lieu_type === 2) {
            final_choix_article = [...final_choix_article, list_article['P-71']];
        } else if (state.deces_lieu_type === 0) {
            final_choix_article = [...final_choix_article, list_article['P-71'], list_article['P-31'], list_article['P-33']];
        } else if (state.deces_lieu_type === 1) {
            final_choix_article = [...final_choix_article, list_article['P-71'], list_article['P-31'], list_article['P-33']];
        } else if (state.deces_lieu_type === 3) {
            final_choix_article = [...final_choix_article, list_article['P-71'], list_article['P-31'], list_article['P-33']];
        }

    }

    // Page 12

    if (state.preparation_du_defunt !== null) {

        let new_article = list_preparation_du_defunt[state.preparation_du_defunt].article.map(item => {
            return list_article[item];
        }).filter(Boolean);

        final_choix_article = [...final_choix_article, ...new_article];

    }

    //

    if (state.urne_selectionne !== null) {

        final_choix_article = [...final_choix_article, state.list_urne[state.urne_selectionne]]

    }

    if (state.cercueil_selectionne !== null) {

        final_choix_article = [...final_choix_article, state.list_cercueil[state.cercueil_selectionne]]

    }

    if (state.capiton_selectionne !== null) {

        final_choix_article = [...final_choix_article, state.list_capiton[state.capiton_selectionne.code]]

    }

    if (state.embleme_selectionne !== null) {

        final_choix_article = [...final_choix_article, state.list_embleme[state.embleme_selectionne.code]]

    }

    if (state.corbillard !== null) {

        let new_article = list_corbillard[state.corbillard].article.map(item => {
            return list_article[item];
        }).filter(Boolean);

        final_choix_article = [...final_choix_article, ...new_article];

    }

    if (state.prestation_complementaire.length) {

        state.prestation_complementaire.forEach(item => {
            let new_article = list_prestations_complementaires[item].article.map(article_code => {
                return list_article[article_code]
            }).filter(Boolean);
            final_choix_article = [...final_choix_article, ...new_article];
        });

    }

    if (state.crematorium_poi !== null) {

        switch (state.crematorium_poi.id) {
            case 165: // Abbeville
                if (typeof list_article['P-17'] !== 'undefined') {
                    final_choix_article = [...final_choix_article, list_article['P-17']];
                }
                break;
            case 166: // Amiens
                if (typeof list_article['P-18'] !== 'undefined') {
                    final_choix_article = [...final_choix_article, list_article['P-18']];
                }
                break;
            case 130: // Beaurain
                if (typeof list_article['P-100'] !== 'undefined') {
                    final_choix_article = [...final_choix_article, list_article['P-100']];
                }
                break;
            case 127: // Bauvais
                if (typeof list_article['P-101'] !== 'undefined') {
                    final_choix_article = [...final_choix_article, list_article['P-101']];
                }
                break;
            case 122: // Herlies
                if (typeof list_article['P-102'] !== 'undefined') {
                    final_choix_article = [...final_choix_article, list_article['P-102']];
                }
                break;
            case 129: // Meru
                if (typeof list_article['P-103'] !== 'undefined') {
                    final_choix_article = [...final_choix_article, list_article['P-103']];
                }
                break;
            case 159: // Rouen
                if (typeof list_article['P-104'] !== 'undefined') {
                    final_choix_article = [...final_choix_article, list_article['P-104']];
                }
                break;
            case 132: // Boulogne
                if (typeof list_article['P-105'] !== 'undefined') {
                    final_choix_article = [...final_choix_article, list_article['P-105']];
                }
                break;
            case 160: // Yvetot
                if (typeof list_article['P-106'] !== 'undefined') {
                    final_choix_article = [...final_choix_article, list_article['P-106']];
                }
                break;
            default:
                if (typeof list_article['P-17'] !== 'undefined') {
                    final_choix_article = [...final_choix_article, list_article['P-17']];
                }
        }

    }

    // console.log( final_choix_article );

    return final_choix_article;

}