import React, {useEffect, useState} from "react";
import {getMaisonDeRepos} from "../misc/api";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import ListPlaceByCommune from "./sub/ListPlaceByCommune";

function ChoixMaisonDeRepos( props ) {

    let history = useHistory();

    const [ listeMaisonDeRepos, setListeMaisonDeRepos ] = useState( [] );

    const coordonnee = useSelector( ( state ) => state.coordonnee );

    const getMaisonDeReposAround = () => {
        getMaisonDeRepos( coordonnee.longitude, coordonnee.latitude, 50 )
            .then( response => {
                if( response.success ){
                    setListeMaisonDeRepos( response.data )
                }
            })
            .catch( error => console.log( error ) );
    }

    useEffect( getMaisonDeReposAround, []);

    return (
        <>
            <ListPlaceByCommune liste={ listeMaisonDeRepos } setpoi={ history.location.state } />
        </>
    );

}

export default ChoixMaisonDeRepos;