import React from "react";
import BottomNav from "./BottomNav";
import {list_recueillement} from "../misc/listing";
import {useDispatch, useSelector} from "react-redux";

function Page10() {

    const recueillement = useSelector( ( state ) => state.recueillement );
    const dispatch = useDispatch();

    return (
        <>
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-2">
                <h1 className="text-center font-gold mt-5 mb-3">Souhaitez-vous pouvoir vous recueillir auprès du défunt?</h1>
                <div className="row justify-content-around mb-5">
                    {
                        list_recueillement.map((value, index) => {
                            return (
                                <div className="col-4 mb-3" key={ index }>
                                    <button type="button" className={ "btn btn-lg btn-block" + ( index === recueillement ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_recueillement', payload: index } ) }>{ value }</button>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
        <BottomNav precedent_page={'/Page9'} suivant_page={'/Page11'} suivant_enable={ recueillement !== null } />
        </>
    );

}

export default Page10;