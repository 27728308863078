import React from "react";
import MyCard from "./MyCard"
import BottomNav from "./BottomNav";

import { useSelector, useDispatch } from "react-redux";
import {demande_concerne_list} from "../misc/listing";

function Page2() {

    const demande_concerne = useSelector( ( state ) => state.demande_concerne );
    const dispatch = useDispatch();

    return (
        <>
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-5">
                <h1 className="text-center font-gold mb-5">Votre demande concerne</h1>
                <div className="row">
                    {
                        demande_concerne_list.map((value, index) => {
                            return (
                                <div className="col-4" key={index}>
                                    <div onClick={ () => {
                                        if( !value.disabled ){
                                            dispatch( { type: 'set_demande_concerne', payload: index } )
                                        }
                                    } }>
                                        <MyCard
                                            title={ value.title }
                                            texte={ value.text }
                                            className={ demande_concerne === index  ? ' active' : '' }
                                            img={ value.img }
                                            disabled={ value.disabled }
                                        />
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
        <BottomNav precedent_page={'/Page1'} suivant_page={'/Page3'} suivant_enable={ demande_concerne !== null }/>
        </>
    );

}

export default Page2;