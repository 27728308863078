import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import NumberFormat from "react-number-format";

function BottomNav( props ){

    const debug = useSelector( state => state.debug )
    const logged_in = useSelector( ( state ) => state.logged_in );
    const list_choix_articles = useSelector( ( state ) => state.list_choix_articles );

    return (
        <footer className="flex-shrink-1">
            {
                debug ? <div className="float-left py-3 ml-3">
                    <Link className="btn btn-warning btn-lg" to={ '/ListeArticle' }>
                        Liste Article
                    </Link>
                </div>
                :
                <div className="float-left py-3 ml-3">
                    <button className="btn btn-warning btn-lg" onClick={ _ => window.location.href = '/' }>Annuler mon devis</button>
                </div>
            }
            {
                logged_in && <div className="float-right py-3 mr-3">
                    <Link className="btn btn-warning btn-lg" to={ '/Devis' }>
                        Total : <NumberFormat value={ list_choix_articles.reduce((a, b) => +a + +b.prix_ttc, 0) } displayType={'text'} thousandSeparator={" "} prefix={'€ '} decimalScale={2} fixedDecimalScale={true} decimalSeparator={","} /> €
                    </Link>
                </div>
            }
            <div className="container py-3">
                <div className="d-flex">
                    <div className="col-6">
                        {
                            props.precedent_page && props.precedent_enable && <Link className="btn btn-warning btn-lg" to={ props.precedent_page }>
                                <FontAwesomeIcon icon={"chevron-left"} />&nbsp;&nbsp;
                                précédent
                            </Link>
                        }
                    </div>
                    <div className="col-6 text-right">
                        {
                            props.suivant_page && props.suivant_enable && <Link className="btn btn-warning btn-lg" to={ props.suivant_page }>
                                suivant&nbsp;&nbsp;
                                <FontAwesomeIcon icon={"chevron-right"} />
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </footer>
    );

}

BottomNav.defaultProps = {
    suivant_enable: true,
    precedent_enable: true
};

export default BottomNav;