import React from "react";
import BottomNav from "./BottomNav";
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import VignetteArticle from "./sub/VignetteArticle";

function Page14() {

    const history = useHistory();

    const capiton_selectionne = useSelector( ( state ) => state.capiton_selectionne );
    const embleme_selectionne = useSelector( ( state ) => state.embleme_selectionne );
    const obseque_type = useSelector( ( state ) => state.obseque_type );

    return (
        <>
            <div className="container flex-grow-1 d-flex justify-content-center">
                <div className="mt-2 d-flex flex-grow-1 flex-column">
                    <h1 className="text-center font-gold mt-5">Choix du capiton & de l’emblème</h1>
                    <h3 className="text-center  mb-5">Le capiton est situé à l’intérieur du cercueil.<br />
                        Il s’agit d’un élément de personnalisation non obligatoire fortement conseillé,<br />l’emblème est optionnel.</h3>
                    <div className="row mb-5 justify-content-center">
                        {
                            capiton_selectionne ?
                                <VignetteArticle
                                    selected={ true }
                                    onClick={ () => history.push('/ChoixCapiton') }
                                    photo={ capiton_selectionne.photo }
                                    photo_replace_text={ 'Pas de photo disponible' }
                                    nom={ capiton_selectionne.nom }
                                    prix={ capiton_selectionne.prix_ttc }
                                    height={ '40px' }
                                />
                                :
                                <VignetteArticle
                                    selected={ true }
                                    onClick={ () => history.push('/ChoixCapiton') }
                                    photo_replace_text={ <span>Aucun capiton<br/>selectionné</span> }
                                    nom={ <span>Touchez ici pour<br/>en sélectionner un</span> }
                                    height={ '40px' }
                                />
                        }
                        {
                            embleme_selectionne ?
                                <VignetteArticle
                                    selected={ true }
                                    onClick={ () => history.push('/ChoixEmbleme') }
                                    photo={ embleme_selectionne.photo }
                                    photo_replace_text={ 'Pas de photo disponible' }
                                    nom={ embleme_selectionne.nom }
                                    prix={ embleme_selectionne.prix_ttc }
                                    height={ '40px' }
                                />
                                :
                                <VignetteArticle
                                    selected={ true }
                                    onClick={ () => history.push('/ChoixEmbleme') }
                                    photo_replace_text={ <span>Aucun emblème<br/>selectionné</span> }
                                    nom={ <span>Touchez ici pour<br/>en sélectionner un</span> }
                                    height={ '40px' }
                                />

                        }
                    </div>
                </div>
            </div>
        <BottomNav precedent_page={'/ChoixCercueil'} suivant_page={ obseque_type === 1 ? '/ChoixUrne' : '/ChoixCorbillard' } />
        </>
    );

}

export default Page14;