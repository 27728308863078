import React from "react";
import BottomNav from "./BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {list_corbillard} from "../misc/listing";
import VignetteArticle from "./sub/VignetteArticle";

function ChoixCorbillard() {

    const corbillard = useSelector( state => state.corbillard );
    const obseque_type = useSelector( ( state ) => state.obseque_type );

    let dispatch = useDispatch();

    return (
        <>
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-2">
                <h1 className="text-center font-gold mt-5 mb-4">Choix du corbillard et de l'accompagnement</h1>
                <div className="row mb-5 justify-content-center">
                    {
                        list_corbillard.map( ( item, index ) => {
                            return <VignetteArticle
                                key={'Corbillard-' + index}
                                selected={ corbillard === index }
                                onClick={ () => dispatch({ type: 'set_corbillard', payload: index }) }
                                photo={ item.img }
                                photo_replace_text={ 'Pas de photo disponible' }
                                nom={ item.nom }
                                description={ item.description }
                                prix={ item.prix }
                                height={ 'unset' }
                            />
                        })
                    }
                </div>
            </div>
        </div>
        <BottomNav precedent_page={ obseque_type === 1 ? '/ChoixUrne' : '/Page14' } suivant_page={'/ChoixPrestationComplementaire'} />
        </>
    );

}

export default ChoixCorbillard;