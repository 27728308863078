import React from "react";
import BottomNav from "./BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import VignetteArticle from "./sub/VignetteArticle";

function ChoixCercueil() {

    const number_on_page = 3;

    const offset_list_cercueil = useSelector( ( state ) => state.offset_list_cercueil );
    const obseque_type = useSelector( ( state ) => state.obseque_type );
    const cercueil_selectionne = useSelector( ( state ) => state.cercueil_selectionne );
    const list_cercueil = useSelector( ( state ) => state.list_cercueil );

    const dispatch = useDispatch();

    const filtered_list_cercueil = Object.values(list_cercueil)
        .filter( (value, index) => {
            return ( obseque_type === 0 && value.inhumation ) || ( obseque_type === 1 && value.cremation )
        }).sort((a, b) => a.prix_ttc - b.prix_ttc );

    const count_filtered_cercueil = filtered_list_cercueil.length;

    const final_list_cercueil = filtered_list_cercueil.slice(offset_list_cercueil, offset_list_cercueil + number_on_page);

    return (
        <>
            <div className="container flex-grow-1 d-flex justify-content-center">
                <div className="mt-2 d-flex flex-grow-1 flex-column">
                    <h1 className="text-center font-gold mt-5">Choix du cercueil</h1>
                    <h3 className="text-center  mb-5">Le cercueil est un élément obligatoire des funérailles.<br />
                        Vous avez la possibilité de le personnaliser grâce à la pose d’un emblème par exemple</h3>
                    <div className="d-flex flex-grow-1">
                        <div className="row mb-5 align-content-around">
                            {
                                final_list_cercueil.map((value, index) => {
                                    return (
                                        <VignetteArticle
                                            key={ index }
                                            selected={ cercueil_selectionne === value.code }
                                            onClick={ () => dispatch({type: 'set_cercueil_selectionne', payload: value.code }) }
                                            photo={ value.photo }
                                            photo_replace_text={ 'Pas de photo pour ce cercueil' }
                                            nom={ value.nom }
                                            prix={ value.prix_ttc }
                                        />
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="d-flex pb-4">
                        <div className="col-4">
                            {
                                offset_list_cercueil > 0 && <button className="btn btn-primary btn-lg" onClick={ () => dispatch({ type: 'set_offset_list_cercueil', payload: (offset_list_cercueil - number_on_page) }) }>
                                    <FontAwesomeIcon icon={"chevron-left"} />&nbsp;&nbsp;
                                    cercueils précédents
                                </button>
                            }
                        </div>
                        <div className="col-4 text-center">
                            <span className="btn btn-outline-dark btn-lg" >Page { ( offset_list_cercueil / number_on_page ) + 1 }  / { Math.ceil( count_filtered_cercueil / number_on_page )  }</span>
                        </div>
                        <div className="col-4 text-right">
                            {
                                offset_list_cercueil + number_on_page < count_filtered_cercueil && <button className="btn btn-primary btn-lg" onClick={ () => dispatch({ type: 'set_offset_list_cercueil', payload: (offset_list_cercueil + number_on_page) }) }>
                                    cercueils suivants&nbsp;&nbsp;
                                    <FontAwesomeIcon icon={"chevron-right"} />
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        <BottomNav precedent_page={'/Page12'} suivant_page={'/Page14'} suivant_enable={ cercueil_selectionne!== null } />
        </>
    );

}

export default ChoixCercueil;