import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import {useDispatch} from "react-redux";

function ListPlaceByCommune( props ) {

    let history = useHistory();

    let dispatch = useDispatch();

    const [ listVille, setListVille ] = useState( [] );
    const [ selectedPlace, setSelectedPlace ] = useState( null );
    const [ selectedVille, setSelectedVille ] = useState( null );

    useEffect(() => {
        let list_ville_temp = {}
        props.liste.forEach( (value, index) => {
            let key_city = value.city.substring(0,3) + '-' + value.municipality_id;
            if( typeof list_ville_temp[ key_city ] === 'undefined' ){
                list_ville_temp[ key_city ] = {
                    id: value.id,
                    municipality_id: value.municipality_id,
                    city_id: value.city_id,
                    postal_code: value.postal_code,
                    city: value.city,
                    villes: value.villes,
                }
            }
        });
        setListVille( Object.values( list_ville_temp ).sort( ( a, b ) => a.city.localeCompare(b.city)) );
    }, [ props.liste ]);


    let liste_poi = [];
    if( selectedVille ){
        props.liste.forEach( ( item, index ) => {
            if( item.municipality_id === selectedVille.municipality_id ){
                liste_poi.push( item )
            }
        })
    }

    const confirmSelectedPlace = ( item ) => {
        if( typeof props.setpoi !== "undefined" ){
            dispatch( { type: props.setpoi, payload: item } )
        }
        history.goBack();
    }

    return (
        <>
            <div className="flex-grow-1 d-flex">
                <div className="mt-4 flex-grow-1 overflow-hidden" style={{ height: '832px'}}>
                    <div className="m-2 d-flex flex-row">
                        {
                            props.liste && props.liste.length === 0 ?
                                <>
                                    <div className="col-12">
                                        <h3 className="text-center">Chargement des lieux en cours...</h3>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-2">
                                        <h3 className="ml-1">Ville</h3>
                                        <ul className="list-group overflow-auto" style={{height: ( 795 - 33 ) + 'px'}}>
                                            {
                                                listVille.map( ( item, index ) => {
                                                    return <li key={ 'v' + item.id } className={ 'list-group-item text-capitalize' + ( selectedVille && selectedVille.municipality_id === item.municipality_id ? ' bg-warning' : '' ) } onClick={ _ => {
                                                        setSelectedPlace(null);
                                                        setSelectedVille(item);
                                                    }}>
                                                        <b>{ item.postal_code + ' ' + item.city }</b><br />
                                                        {
                                                            item.villes && item.villes.length > 0 && item.villes.map( ( value, index ) => {
                                                                return <span>{ value }</span>
                                                            })
                                                        }
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="col-3">
                                        <h3 className="ml-1">Lieu</h3>
                                        {
                                            liste_poi.length > 0 ?
                                                <ul className="list-group overflow-auto" style={{height: ( 795 - 33 ) + 'px'}}>
                                                    {
                                                        liste_poi.map( ( item, index ) => {
                                                            return item.municipality_id === selectedVille.municipality_id ? <li key={ 'poi-' + item.id } className={ 'list-group-item text-capitalize' + ( selectedPlace && selectedPlace.id === item.id ? ' bg-warning' : '' ) } onClick={ () => setSelectedPlace( item ) }>
                                                                <b>{ item.name }</b><br />
                                                                { typeof item.street !== 'undefined' && item.street && item.street.length > 0 ? <>{ item.number + ' ' + item.street }<br/></> : '' }
                                                                { item.postal_code + ' ' + item.city }</li> : null
                                                        })
                                                    }
                                                </ul> : (
                                                    selectedVille !== null && <h2>Pas d'hopital</h2>
                                                )
                                        }
                                    </div>
                                    <div className="col-7">
                                        <h3 className="ml-1">&nbsp;</h3>
                                        {
                                            selectedPlace !== null && <div className={'card'} style={{height: ( 795 - 33 ) + 'px'}}>
                                                <div className="card-body">
                                                    <div>
                                                        <button className="float-right btn btn-warning"
                                                                onClick={ () => confirmSelectedPlace( selectedPlace ) }
                                                        >
                                                            <FontAwesomeIcon icon={'check'} />
                                                            &nbsp;&nbsp;Choisir ce lieu
                                                        </button>
                                                        <h3>{ selectedPlace.name }</h3>
                                                        <h6>
                                                            { selectedPlace.street }<br/>
                                                            { selectedPlace.postal_code + ' ' + selectedPlace.city }
                                                        </h6>
                                                        <div className="d-flex">
                                                            {
                                                                selectedPlace.streetview_url && <img className="img-thumbnail" alt="streetview" src={ selectedPlace.streetview_url.replace('AIzaSyB3WdGvSwzDQKCfayj_ofDpXHzYP27CZw8', 'AIzaSyCVXRDwCBrxEiu9fSdl5cuL8FEa38Ac5Aw') } />
                                                            }
                                                            {
                                                                selectedPlace.static_map_url && <img className="img-thumbnail" alt="map" src={ selectedPlace.static_map_url.replace('AIzaSyB3WdGvSwzDQKCfayj_ofDpXHzYP27CZw8', 'AIzaSyCVXRDwCBrxEiu9fSdl5cuL8FEa38Ac5Aw').replace('satellite','map') } />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </>

                        }
                    </div>
                </div>
            </div>
            <footer className="flex-shrink-1">
                <div className="py-3">
                    <div className="d-flex">
                        <div className="col-12">
                            <button onClick={ history.goBack } className={'btn btn-warning btn-lg'}>
                                <FontAwesomeIcon icon={"chevron-left"} />&nbsp;&nbsp;
                                Retour
                            </button>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )

}

export default ListPlaceByCommune