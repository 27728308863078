import React from "react";
import BottomNav from "./BottomNav";
import {list_preparation_du_defunt} from "../misc/listing";
import {useDispatch, useSelector} from "react-redux";

function Page12() {

    const preparation_du_defunt = useSelector( ( state ) => state.preparation_du_defunt );
    const dispatch = useDispatch();

    return (
        <>
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-2 d-flex flex-grow-1 flex-column">
                <h1 className="text-center font-gold mt-5 mb-5">Préparation du défunt</h1>
                <div className="d-flex justify-content-around mb-5 align-content-around">
                    {
                        list_preparation_du_defunt.map((value, index) => {
                            return (
                                <div className="mb-3" key={ index }>
                                    <button type="button" className={ "btn btn-lg btn-block" + ( index === preparation_du_defunt ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_preparation_du_defunt', payload: index } ) }>{ value.nom }</button>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
        <BottomNav precedent_page={'/Page11'} suivant_page={'/ChoixCercueil'} />
        </>
    );

}

export default Page12;