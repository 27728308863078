import React from "react";

function VignettePhoto( props ) {

    return (

        <div className={ props.containerClass }>
            <div className={ "card" + ( props.selected ? " active" : "" ) } onClick={ props.onClick }>
                {
                    props.displayPhoto && <>
                        {
                            !!props.img ?
                                <img className="card-img-top" src={ props.img } alt={ props.nom } style={{height: props.heightPhoto, objectFit: 'cover', backgroundColor:'white'}} />
                                :
                                <div className="d-flex justify-content-center align-content-center bg-white" style={{height: '250px'}}>
                                    <h5 className="align-self-center text-dark text-center">{ props.photo_replace_text }</h5>
                                </div>
                        }
                    </>
                }
                <div className="card-body">
                    <h5 className="card-title" style={{ height: props.height }}>{ props.nom }</h5>
                </div>
            </div>
        </div>

    )

}

VignettePhoto.defaultProps = {
    height: 'unset',
    heightPhoto: '250px',
    photo_replace_text: 'Pas de photo',
    selected: false,
    displayPhoto: true,
    containerClass: 'col-4 mb-3'
}

export default VignettePhoto;