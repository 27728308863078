import React from "react";
import BottomNav from "./BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {list_lieu_de_repos_type, list_lieu_de_repos_funerarium, list_recueillement} from "../misc/listing";

function Page11() {

    const lieu_de_repos_type = useSelector( ( state ) => state.lieu_de_repos_type );
    const lieu_de_repos_funerarium = useSelector( ( state ) => state.lieu_de_repos_funerarium );
    const deces_lieu_type = useSelector( ( state ) => state.deces_lieu_type );
    const recueillement = useSelector( ( state ) => state.recueillement );
    const dispatch = useDispatch();

    return (
        <>
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-2 d-flex flex-grow-1 flex-column px-5">
                <h1 className="text-center font-gold mt-5 mb-3">Lieu de repos du défunt</h1>
                <div className="d-flex justify-content-around mb-5 align-content-around">
                {
                    list_lieu_de_repos_type.map((value, index) => {
                        return deces_lieu_type === 2 && index === 2 ?
                            null
                            :
                            (
                            <div className="mb-3" key={ index }>
                                <button type="button" className={ "btn btn-lg btn-block" + ( index === lieu_de_repos_type ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_lieu_de_repos_type', payload: index } ) }>{ value.nom }</button>
                            </div>
                        );
                    })
                }
                </div>
                {
                    lieu_de_repos_type === 0 && <>
                        <h3 className="mt-5 mb-3">Choix du funérariums</h3>
                        <div className="d-flex justify-content-around align-content-around">
                        {
                            list_lieu_de_repos_funerarium.map((value, index) => {
                                return (
                                    <div className="mb-3" key={ index }>
                                        <button type="button" className={ "btn btn-lg btn-block" + ( index === lieu_de_repos_funerarium ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_lieu_de_repos_funerarium', payload: index } ) }>{ value }</button>
                                    </div>
                                );
                            })
                        }
                        </div>
                        <p className="mb-5">d’autres Funérariums sont disponibles sur les communes d’Amiens, Flixecourt, Oisemont, Saint-Valéry-sur-Somme, Rue, Friville Escarbotin, Feuquières en Vimeu</p>
                    </>
                }
                {
                    lieu_de_repos_type !== null && <>
                        <h1 className="text-center font-gold mt-5 mb-3">Souhaitez-vous pouvoir vous recueillir auprès du défunt?</h1>
                        <div className="row justify-content-around mb-5">
                            {
                                list_recueillement.map((value, index) => {
                                    return (
                                        <div className="col-4 mb-3" key={ index }>
                                            <button type="button" className={ "btn btn-lg btn-block" + ( index === recueillement ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_recueillement', payload: index } ) }>{ value.nom }</button>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </>
                }
            </div>
        </div>
        <BottomNav precedent_page={'/Page9'} suivant_page={'/Page12'} />
        </>
    );

}

export default Page11;