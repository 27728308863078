import React from "react";
import BottomNav from "./BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import VignetteArticle from "./sub/VignetteArticle";

function ChoixCercueil() {

    const number_on_page = 3;

    const offset_list_urne = useSelector( ( state ) => state.offset_list_urne );
    const urne_selectionne = useSelector( ( state ) => state.urne_selectionne );
    const list_urne = useSelector( ( state ) => state.list_urne );

    const dispatch = useDispatch();

    const filtered_list_urne = Object.values(list_urne).sort((a, b) => a.prix_ttc - b.prix_ttc );

    const count_filtered_urne = filtered_list_urne.length;

    const final_list_urne = filtered_list_urne.slice(offset_list_urne, offset_list_urne + number_on_page);

    return (
        <>
            <div className="container flex-grow-1 d-flex justify-content-center">
                <div className="mt-2 d-flex flex-grow-1 flex-column">
                    <h1 className="text-center font-gold mt-5">Choix de l'urne</h1>
                    <div className="d-flex flex-grow-1">
                        <div className="row mb-5 align-content-around">
                            {
                                final_list_urne.map((value, index) => {
                                    return (
                                        <VignetteArticle
                                            key={ index }
                                            selected={ urne_selectionne === value.code }
                                            onClick={ () => dispatch({type: 'set_urne_selectionne', payload: value.code }) }
                                            photo={ value.photo }
                                            photo_replace_text={ 'Pas de photo pour cette urne' }
                                            nom={ value.nom }
                                            prix={ value.prix_ttc }
                                        />
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="d-flex pb-4">
                        <div className="col-4">
                            {
                                offset_list_urne > 0 && <button className="btn btn-primary btn-lg" onClick={ () => dispatch({ type: 'set_offset_list_urne', payload: (offset_list_urne - number_on_page) }) }>
                                    <FontAwesomeIcon icon={"chevron-left"} />&nbsp;&nbsp;
                                    urnes précédentes
                                </button>
                            }
                        </div>
                        <div className="col-4 text-center">
                            <span className="btn btn-outline-dark btn-lg" >Page { ( offset_list_urne / number_on_page ) + 1 }  / { Math.ceil( count_filtered_urne / number_on_page )  }</span>
                        </div>
                        <div className="col-4 text-right">
                            {
                                offset_list_urne + number_on_page < count_filtered_urne && <button className="btn btn-primary btn-lg" onClick={ () => dispatch({ type: 'set_offset_list_urne', payload: (offset_list_urne + number_on_page) }) }>
                                    urnes suivantes&nbsp;&nbsp;
                                    <FontAwesomeIcon icon={"chevron-right"} />
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        <BottomNav precedent_page={'/Page14'} suivant_page={'/ChoixCorbillard'} suivant_enable={ urne_selectionne !== null } />
        </>
    );

}

export default ChoixCercueil;