import React from "react";
import {
    Link
} from "react-router-dom";

function Page1() {

    return (
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-5">
                <h1 className="text-center font-gold mb-5 mt-5">Votre devis personnalisé en moins de 5 minutes<br/>Gratuit,
                    confidentiel et sans engagement.</h1>
                <p className="text-center h4 px-5">
                    Notre configurateur d’obsèques en ligne vous permet d’obtenir une estimation<br/>gratuite et
                    personnalisée des frais funéraires.<br/><br/>
                    En cas de besoin, vous pouvez faire appel à nos conseillers disponibles<br/>par téléphone ou par
                    mail.<br/><br/><br/>
                    Tous nos prix sont affichés sur notre site<br/>car la transparence est pour nous une évidence.
                </p>
                <p className="text-center h4 px-5 mt-5">
                    <Link className="btn btn-warning btn-lg mt-2" to="/page2">Obtenir mon devis</Link>
                </p>
            </div>
        </div>
    );

}

export default Page1;